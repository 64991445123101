import React from 'react';
import registeredUsersIcon from '../../../assets/registered-users-icon.png';
import activeLoginsIcon from '../../../assets/active-logins-icon.png';
import activeDevicesIcon from '../../../assets/active-devices-icon.png';
import taxValueIcon from '../../../assets/tax-value-icon.png';

export enum CustomIconType {
  ActiveUsersIcon,
  RegisteredUsersIcon,
  ActiveLoginsIcon,
  ActiveDevicesIcon,
  ActiveTaxValueIcon,
  SaveIcon,
  SidebarIconAdmin,
  SidebarIconUsers,
  SidebarIconDashboard,
  SidebarTestIcon1,
  SidebarTestIcon2,
  SidebarTestIcon3,
  SidebarTestIcon4,
  SidebarTestIcon5,
  SidebarIconBilling,
  HeaderAlertsIcon,
  HeaderHomeIcon,
  HeaderSearchIcon,
  HeaderUserIcon,
  LeftArrowIcon,
  RightArrowIcon,
  DownArrowIcon,
  Activity,
  AccountInfoIcon,
  MobileDeviceIcon,
  DesktopDeviceIcon,
  SidebarMyInfo,
  ChangePasswordIcon,
  CheckCircleOutline,
}

export interface ICustomIconProps {
  iconType: CustomIconType;
  rawSvg?: boolean;
  style?: object;
}

const getRawSvg = (
  iconType: CustomIconType,
  style?: object
): JSX.Element | null => {
  switch (iconType) {
    case CustomIconType.SaveIcon: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z'
          />
        </svg>
      );
    }
    case CustomIconType.SidebarIconAdmin: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,4A8,8 0 0,1 20,12C20,14.4 19,16.5 17.3,18C15.9,16.7 14,16 12,16C10,16 8.2,16.7 6.7,18C5,16.5 4,14.4 4,12A8,8 0 0,1 12,4M14,5.89C13.62,5.9 13.26,6.15 13.1,6.54L11.81,9.77L11.71,10C11,10.13 10.41,10.6 10.14,11.26C9.73,12.29 10.23,13.45 11.26,13.86C12.29,14.27 13.45,13.77 13.86,12.74C14.12,12.08 14,11.32 13.57,10.76L13.67,10.5L14.96,7.29L14.97,7.26C15.17,6.75 14.92,6.17 14.41,5.96C14.28,5.91 14.15,5.89 14,5.89M10,6A1,1 0 0,0 9,7A1,1 0 0,0 10,8A1,1 0 0,0 11,7A1,1 0 0,0 10,6M7,9A1,1 0 0,0 6,10A1,1 0 0,0 7,11A1,1 0 0,0 8,10A1,1 0 0,0 7,9M17,9A1,1 0 0,0 16,10A1,1 0 0,0 17,11A1,1 0 0,0 18,10A1,1 0 0,0 17,9Z'
          />
        </svg>
      );
    }
    case CustomIconType.SidebarIconDashboard: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M22,21H2V3H4V19H6V10H10V19H12V6H16V19H18V14H22V21Z'
          />
        </svg>
      );
    }
    case CustomIconType.SidebarIconUsers: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z'
          />
        </svg>
      );
    }
    case CustomIconType.SidebarTestIcon1: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M22,21H2V3H4V19H6V10H10V19H12V6H16V19H18V14H22V21Z'
          />
        </svg>
      );
    }
    case CustomIconType.SidebarTestIcon2: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z'
          />
        </svg>
      );
    }
    case CustomIconType.SidebarTestIcon3: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M20,18H4V8H20M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z'
          />
        </svg>
      );
    }
    case CustomIconType.SidebarTestIcon4: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M19,19H5V5H19M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M13.96,12.29L11.21,15.83L9.25,13.47L6.5,17H17.5L13.96,12.29Z'
          />
        </svg>
      );
    }
    case CustomIconType.SidebarTestIcon5: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M11.45,2V5.55L15,3.77L11.45,2M10.45,8L8,10.46L11.75,11.71L10.45,8M2,11.45L3.77,15L5.55,11.45H2M10,2H2V10C2.57,10.17 3.17,10.25 3.77,10.25C7.35,10.26 10.26,7.35 10.27,3.75C10.26,3.16 10.17,2.57 10,2M17,22V16H14L19,7V13H22L17,22Z'
          />
        </svg>
      );
    }
    case CustomIconType.HeaderAlertsIcon: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M10 21H14C14 22.1 13.1 23 12 23S10 22.1 10 21M21 19V20H3V19L5 17V11C5 7.9 7 5.2 10 4.3V4C10 2.9 10.9 2 12 2S14 2.9 14 4V4.3C17 5.2 19 7.9 19 11V17L21 19M17 11C17 8.2 14.8 6 12 6S7 8.2 7 11V18H17V11Z'
          />
        </svg>
      );
    }
    case CustomIconType.HeaderHomeIcon: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M12 5.69L17 10.19V18H15V12H9V18H7V10.19L12 5.69M12 3L2 12H5V20H11V14H13V20H19V12H22L12 3Z'
          />
        </svg>
      );
    }
    case CustomIconType.HeaderSearchIcon: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z'
          />
        </svg>
      );
    }
    case CustomIconType.HeaderUserIcon: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M12,19.2C9.5,19.2 7.29,17.92 6,16C6.03,14 10,12.9 12,12.9C14,12.9 17.97,14 18,16C16.71,17.92 14.5,19.2 12,19.2M12,5A3,3 0 0,1 15,8A3,3 0 0,1 12,11A3,3 0 0,1 9,8A3,3 0 0,1 12,5M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z'
          />
        </svg>
      );
    }
    case CustomIconType.LeftArrowIcon: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z'
          />
        </svg>
      );
    }
    case CustomIconType.SidebarIconBilling: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z'
          />
        </svg>
      );
    }
    case CustomIconType.Activity: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M13.5,5.5C14.59,5.5 15.5,4.58 15.5,3.5C15.5,2.38 14.59,1.5 13.5,1.5C12.39,1.5 11.5,2.38 11.5,3.5C11.5,4.58 12.39,5.5 13.5,5.5M9.89,19.38L10.89,15L13,17V23H15V15.5L12.89,13.5L13.5,10.5C14.79,12 16.79,13 19,13V11C17.09,11 15.5,10 14.69,8.58L13.69,7C13.29,6.38 12.69,6 12,6C11.69,6 11.5,6.08 11.19,6.08L6,8.28V13H8V9.58L9.79,8.88L8.19,17L3.29,16L2.89,18L9.89,19.38Z'
          />
        </svg>
      );
    }
    case CustomIconType.RightArrowIcon: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z'
          />
        </svg>
      );
    }
    case CustomIconType.AccountInfoIcon: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6C9.66 6 11 7.34 11 9M14 20H2V18C2 15.79 4.69 14 8 14C11.31 14 14 15.79 14 18M22 12V14H13V12M22 8V10H13V8M22 4V6H13V4Z'
          />
        </svg>
      );
    }
    case CustomIconType.MobileDeviceIcon: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M17,19H7V5H17M17,1H7C5.89,1 5,1.89 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3C19,1.89 18.1,1 17,1Z'
          />
        </svg>
      );
    }
    case CustomIconType.DesktopDeviceIcon: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M21,16H3V4H21M21,2H3C1.89,2 1,2.89 1,4V16A2,2 0 0,0 3,18H10V20H8V22H16V20H14V18H21A2,2 0 0,0 23,16V4C23,2.89 22.1,2 21,2Z'
          />
        </svg>
      );
    }
    case CustomIconType.SidebarMyInfo: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z'
          />
        </svg>
      );
    }
    case CustomIconType.DownArrowIcon: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z'
          />
        </svg>
      );
    }
    case CustomIconType.ChangePasswordIcon: {
      return (
        <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M12.63,2C18.16,2 22.64,6.5 22.64,12C22.64,17.5 18.16,22 12.63,22C9.12,22 6.05,20.18 4.26,17.43L5.84,16.18C7.25,18.47 9.76,20 12.64,20A8,8 0 0,0 20.64,12A8,8 0 0,0 12.64,4C8.56,4 5.2,7.06 4.71,11H7.47L3.73,14.73L0,11H2.69C3.19,5.95 7.45,2 12.63,2M15.59,10.24C16.09,10.25 16.5,10.65 16.5,11.16V15.77C16.5,16.27 16.09,16.69 15.58,16.69H10.05C9.54,16.69 9.13,16.27 9.13,15.77V11.16C9.13,10.65 9.54,10.25 10.04,10.24V9.23C10.04,7.7 11.29,6.46 12.81,6.46C14.34,6.46 15.59,7.7 15.59,9.23V10.24M12.81,7.86C12.06,7.86 11.44,8.47 11.44,9.23V10.24H14.19V9.23C14.19,8.47 13.57,7.86 12.81,7.86Z'
          />
        </svg>
      );
    }
    default: {
      return null;
    }
  }
};

const getStyledSvg = (
  iconType: CustomIconType,
  style?: object
): JSX.Element | null => {
  switch (iconType) {
    case CustomIconType.CheckCircleOutline: {
      return (
        <svg style={style} viewBox='0 0 24 24'>
          <path
            fill='currentColor'
            d='M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z'
            stroke='#ffffff'
            stroke-width='1px'
          />
        </svg>
      );
    }

    default: {
      return null;
    }
  }
};

const getIconImage = (iconType: CustomIconType) => {
  switch (iconType) {
    case CustomIconType.ActiveUsersIcon:
    case CustomIconType.RegisteredUsersIcon: {
      return registeredUsersIcon;
    }
    case CustomIconType.ActiveLoginsIcon: {
      return activeLoginsIcon;
    }
    case CustomIconType.ActiveDevicesIcon: {
      return activeDevicesIcon;
    }
    case CustomIconType.ActiveTaxValueIcon: {
      return taxValueIcon;
    }
    default: {
      // TODO: Default case
    }
  }
};

const getAltText = (iconType: CustomIconType) => {
  switch (iconType) {
    case CustomIconType.ActiveUsersIcon: {
      return 'Active Users Icon';
    }
    case CustomIconType.RegisteredUsersIcon: {
      return 'Registered Users Icon';
    }
    case CustomIconType.ActiveLoginsIcon: {
      return 'Active Logins Icon';
    }
    case CustomIconType.ActiveDevicesIcon: {
      return 'Active Devices Icon';
    }
    case CustomIconType.ActiveTaxValueIcon: {
      return 'Total Tax Value Icon';
    }
    case CustomIconType.SaveIcon: {
      return 'Save Icon';
    }
    default: {
      // TODO: Default case
    }
  }
};

export const StyledCustomIcon = (props: ICustomIconProps) => {
  return props.style ? (
    getStyledSvg(props.iconType, props.style)
  ) : (
    <img
      src={getIconImage(props.iconType)}
      alt={getAltText(props.iconType)}></img>
  );
};

export const CustomIcon = (props: ICustomIconProps) => {
  return props.rawSvg ? (
    getRawSvg(props.iconType, props.style)
  ) : (
    <img
      src={getIconImage(props.iconType)}
      alt={getAltText(props.iconType)}></img>
  );
};
