import { gql } from '@apollo/client';

export const UPDATE_ACTIVE_QUICKSIGHT_DASHBOARD = gql`
  mutation update($dashboardId: String) {
    updateActiveQuickSightDashboard(dashboardId: $dashboardId) {
      defaultDashboardId
    }
  }
`;

export const UPDATE_BILLING = gql`
  mutation update($value: Boolean) {
    updateBillingComplete(value: $value) {
      user_id
    }
  }
`;

export const SEND_PASSWORD_EMAIL = gql`
  mutation sendEmail {
    sendUpdatePasswordEmail {
      response
    }
  }
`;

export const DELETE_CURRENT_USER_DEVICE = gql`
  mutation deleteDevice($device_id: String) {
    deleteCurrentUserDeviceCredentials(device_id: $device_id) {
      success
    }
  }
`;

export const UPDATE_MFA = gql`
  mutation update($value: Boolean) {
    updateUseMfa(value: $value) {
      user_id
    }
  }
`;

export const CREATE_STRIPE_CUSTOMER = gql`
  mutation create($email: String) {
    createStripeCustomer(email: $email) {
      id
      email
    }
  }
`;

export const CREATE_STRIPE_SUBSCRIPTION = gql`
  mutation createSub($customer: String, $price_id: String, $setup_id: String) {
    createStripeSubscription(
      customer: $customer
      price_id: $price_id
      setup_id: $setup_id
    ) {
      id
    }
  }
`;

export const CREATE_STRIPE_SETUP_INTENT = gql`
  mutation create($customer: String) {
    createStripeSetupIntent(customer: $customer) {
      id
      client_secret
    }
  }
`;

export const UPDATE_CURRENT_USER = gql`
  mutation update(
    $data: UserInput
    $updateEmail: Boolean
    $user_metadata: Auth0MetaDataInput
  ) {
    updateCurrentUser(
      data: $data
      updateEmail: $updateEmail
      user_metadata: $user_metadata
    ) {
      user_id
    }
  }
`;

export const CREATE_PORTAL_SESSION = gql`
  mutation session($customer_id: String, $return_url: String) {
    createStripePortalSession(
      customer_id: $customer_id
      return_url: $return_url
    ) {
      url
      return_url
    }
  }
`;

export const UPDATE_BLOCKED_STATUS = gql`
  mutation updateUserBlockStatus(
    $user_id: String
    $subscription_id: String
    $value: Boolean
  ) {
    updateUserBlockStatus(
      user_id: $user_id
      subscription_id: $subscription_id
      value: $value
    ) {
      user_id
    }
  }
`;
