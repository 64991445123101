import { useLazyQuery } from '@apollo/client';
import {
  GET_USER_FOR_DASHBOARD,
  GET_CURRENT_USER_FOR_DASHBOARD,
} from './common/GraphQLQueries';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useAppFlowContext } from '../context/AppFlowProvider';
import ChangePassword from './ChangePassword';
import Loading from './common/Loading';
import { MUIModal } from './common/MUIModal';
import DevicesList from './DevicesList';
import { IUser } from './Types';
import { currentlyActive } from './helpers/helperFunctions';
import { Button } from './common/buttons/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AcceptButton } from './common/buttons/Button';
import './UserDashboard.css';
import EnableMfa from './EnableMfa';
import BlockedStatusCell from './common/BlockedStatusCell';
import { Breadcrumbs } from './common/Breadcrumbs';

dayjs.extend(localizedFormat);

type UserDashboardParams = {
  id: string;
};

const UserDashboard = () => {
  const { id } = useParams<UserDashboardParams>();
  const { currentUser, isAdmin, setErrorMessage } = useAppFlowContext();
  const [user, setUser] = useState<IUser>();
  const history = useHistory();
  const [isCurrentUser, setIsCurrentUser] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const flipModal = () => setShowModal((current) => !current);

  const [getUser, { loading }] = useLazyQuery(GET_USER_FOR_DASHBOARD, {
    onCompleted: (data: any) => setUser(data.user),
    onError: () => {
      setErrorMessage(true);
    },
  });

  const [getCurrentUser, { loading: currentUserLoading }] = useLazyQuery(
    GET_CURRENT_USER_FOR_DASHBOARD,
    {
      onCompleted: (data: any) => setUser(data.currentUser),
      onError: () => {
        setErrorMessage(true);
      },
    }
  );

  useEffect(() => {
    if (id) {
      if (id === currentUser?.user_id) {
        setIsCurrentUser(true);
      }
      getUser({
        variables: {
          user_id: id,
        },
      });
    } else {
      setIsCurrentUser(true);
      getCurrentUser();
    }
  }, [currentUser, getCurrentUser, getUser, id]);

  const lastLogin = () => {
    if (!user?.last_login) {
      return 'No login data available';
    } else {
      const lastLogin = dayjs(user?.last_login);
      return lastLogin.format('lll'); // MMM D, YYYY h:mm A
    }
  };

  const ViewBillingButton = () => {
    return (
      <>
        {isCurrentUser && (
          <Button
            text='View'
            onClickHandler={() => {
              history.push('/billing');
            }}
            className='txtBtn view-btn'
          />
        )}
      </>
    );
  };

  const BillingDetails = () => {
    if (!user?.customerInfo?.latest_invoice?.payment?.card_details) {
      return (
        <>
          <p>
            No Billing Details Available <ViewBillingButton />
          </p>
        </>
      );
    }

    return (
      <p>
        {user.customerInfo.latest_invoice.payment.card_details.brand.toUpperCase()}
        : **** **** ****
        {user.customerInfo.latest_invoice.payment.card_details.last4}
        <ViewBillingButton />
        <br />
        Exp:{' '}
        {new Date(
          user.customerInfo.latest_invoice.payment.card_details.exp_year,
          user.customerInfo.latest_invoice.payment.card_details.exp_month
        ).toLocaleDateString('en-us', {
          year: 'numeric',
          month: '2-digit',
        })}
        <br />
        Next billing date:{' '}
        {user.customerInfo.latest_invoice.end_date
          ? dayjs
              .unix(user.customerInfo.latest_invoice.end_date)
              .add(1, 'day')
              .format('MM/DD/YYYY')
          : 'n/a'}
      </p>
    );
  };

  const EnableMfaCheckbox = () => {
    return (
      <div className='two-factor'>
        <h3>2 Factor Authentication</h3>
        <EnableMfa value={!!user?.user_metadata?.use_mfa} /> Enable 2 Factor
        Authentication
        <p>Changes will become active on next login.</p>
      </div>
    );
  };

  const BlockedStatusCheckbox = () => {
    if (
      user?.user_metadata?.blocked === undefined ||
      user?.customerInfo?.subscription?.id === undefined
    ) {
      return null;
    }

    return (
      <p>
        <BlockedStatusCell
          value={user?.user_metadata?.blocked || false}
          user_id={user?.user_id}
          subscription_id={user?.customerInfo.subscription.id}
        />
        Disable Account
      </p>
    );
  };

  if (loading || currentUserLoading) {
    return <Loading></Loading>;
  }

  return (
    <>
      {isAdmin && (
        <Breadcrumbs
          primary='Admin'
          primaryClickHandler={() => history.push('/admin/dashboard')}
          current={user?.profile.first_name || 'User'}
          secondary='Users'
          secondaryClickHandler={() => history.push('/admin/users')}
        />
      )}
      <div className='page-title'>
        {user?.profile?.first_name} {user?.profile?.last_name}
      </div>
      <div className='user-info'>
        <p>
          {user?.profile?.address.street}
          <br />
          {user?.profile?.address.city}, {user?.profile?.address.state}{' '}
          {user?.profile?.address.zip_code}
          <br />
          {user?.profile?.phone_number}
          <br />
          {user?.email}
          <br />
        </p>
        <div className='force-btn-group'>
          {isCurrentUser && <ChangePassword onClickHandler={flipModal} />}
        </div>
      </div>
      <div className='user-details'>
        <div className='user-dashboard-card'>
          {!isAdmin && (
            <>
              <p className='user-details-title'>Account Information</p>
              <div className='user-dash-account'>
                <div>
                  Subscription:{' '}
                  {user?.customerInfo?.subscription?.tier?.name || 'n/a'}
                  <ViewBillingButton />
                </div>
                <BillingDetails />
              </div>
            </>
          )}
          {isCurrentUser && <EnableMfaCheckbox />}
          {id && !isCurrentUser && <BlockedStatusCheckbox />}
        </div>
        <div className='user-dashboard-card'>
          <div className='card-title'>Devices</div>
          <DevicesList devices={user?.devices || []} />
        </div>
        <div className='user-dashboard-card'>
          <p className='user-details-title'>Activity</p>
          <p>
            Currently Active:{' '}
            {currentlyActive(user?.last_login || '') ? 'Yes' : 'No'}
            <br />
            Last Login:{` ${lastLogin()}`}
          </p>
          <div className='user-activity-btn'>
            {isCurrentUser ? (
              <Button
                text='view more'
                className='txtBtn'
                onClickHandler={() => history.push('/activity')}
              />
            ) : null}
          </div>
        </div>
      </div>
      <MUIModal
        open={showModal}
        children={
          <Box className='box-style'>
            <Typography
              id='modal-modal-description'
              className='modal-description'>
              Your password is being updated. Please check your email to
              complete the update.
              <div className='modal-nav'>
                <AcceptButton onClickHandler={flipModal} text='OK' />
              </div>
            </Typography>
          </Box>
        }></MUIModal>
    </>
  );
};

export default UserDashboard;
