import { gql } from '@apollo/client';

export const GET_TOTAL_USERS = gql`
  query GetUsers {
    userTotals {
      registered_users
      active_users
      active_logins
    }
  }
`;

export const GET_DAILY_STATS = gql`
  query GetStats {
    dailyStats {
      date
      logins
    }
  }
`;
export const GET_INCOME = gql`
  query incomeForPeriod($start: String, $end: String) {
    incomeForPeriod(start: $start, end: $end) {
      start
      end
      total
    }
  }
`;

export const GET_EXPENSES = gql`
  query expensesForPeriod($start: String, $end: String) {
    expensesForPeriod(start: $start, end: $end) {
      start
      end
      total
    }
  }
`;

export const GET_DASHBOARD_URL = gql`
  query Quicksight {
    dashboardEmbedUrl {
      EmbedUrl
    }
  }
`;

export const GET_QUICKSIGHT_DASHBOARDS = gql`
  query getAvailableDashboards {
    quickSightDashboards {
      DashboardId
      Name
      PublishedVersionNumber
      LastPublishedTime
      Active
    }
  }
`;

export const GET_USER_ACTIVITY_DATA = gql`
  query GetUsers {
    currentUser {
      activity_data {
        country
        city
        user_agent
        ip
        timestamp
      }
    }
  }
`;

export const GET_SUBSCRIPTION = gql`
  query subscriptions {
    subscriptionTiers {
      id
      name
      description
      price
    }
  }
`;

export const GET_CURRENT_USER = gql`
  query getCurrentUser {
    currentUser {
      user_id
      email
      email_verified
      last_login
      profile {
        first_name
        last_name
        phone_number
        address {
          street
          city
          state
          zip_code
        }
      }
      company {
        name
        email
        phone_number
        address {
          street
          city
          state
          zip_code
        }
      }
      roles {
        id
        name
      }
      settings {
        profile_complete
        billing_complete
      }
      customerInfo {
        customer_id
        subscription {
          status
          tier {
            name
          }
        }
      }
    }
  }
`;

export const GET_USER_FOR_DASHBOARD = gql`
  query getUser($user_id: String) {
    user(user_id: $user_id) {
      user_id
      email
      last_login
      user_metadata {
        blocked
        use_mfa
      }
      profile {
        first_name
        last_name
        phone_number
        address {
          street
          city
          state
          zip_code
        }
      }
      devices {
        id
        device_name
        last_used
      }
      customerInfo {
        subscription {
          id
          tier {
            name
          }
        }
        latest_invoice {
          id
          end_date
          payment {
            id
            card_details {
              brand
              exp_month
              exp_year
              last4
            }
          }
        }
      }
    }
  }
`;

export const GET_USER_TOTALS = gql`
  query getTotal {
    userTotals {
      registered_users
    }
  }
`;

export const GET_USERS = gql`
  query GetUsers(
    $page: Int
    $per_page: Int
    $field: String
    $sort: String
    $filter: String
    $filter_field: String
  ) {
    users(
      page: $page
      per_page: $per_page
      field: $field
      sort: $sort
      filter: $filter
      filter_field: $filter_field
    ) {
      user_id
      last_login
      user_metadata {
        blocked
      }
      email
      name
      customerInfo {
        subscription {
          id
          tier {
            name
          }
        }
      }
      profile {
        first_name
        last_name
      }
    }
  }
`;

export const GET_CURRENT_USER_FOR_DASHBOARD = gql`
  query getCurrentUser {
    currentUser {
      user_id
      email
      last_login
      user_metadata {
        blocked
        use_mfa
      }
      profile {
        first_name
        last_name
        phone_number
        address {
          street
          city
          state
          zip_code
        }
      }
      devices {
        id
        device_name
        last_used
      }
      customerInfo {
        subscription {
          id
          tier {
            name
          }
        }
        latest_invoice {
          id
          end_date
          payment {
            id
            card_details {
              brand
              exp_month
              exp_year
              last4
            }
          }
        }
      }
    }
  }
`;

export const GET_TOKEN_LIFETIME = gql`
  query getTokenLife($clientId: String) {
    getClient(clientId: $clientId) {
      client_id
      refresh_token {
        token_lifetime
        idle_token_lifetime
      }
    }
  }
`;
