import { BottomNav } from './common/BottomNav';

interface IBillingBottomNav {
  cancelOnClickHandler: () => void;
  submitOnClickHandler: () => void;
  backOnClickHandler: () => void;
  disabled?: boolean;
}

export const ModifyBillingBottomNav = (
  props: IBillingBottomNav
): JSX.Element => {
  return (
    <BottomNav
      disabled={props.disabled}
      cancelOnClickHandler={props.cancelOnClickHandler}
      submitOnClickHandler={props.submitOnClickHandler}
      backOnClickHandler={props.backOnClickHandler}
      submitText={'Save and Continue'}
      cancelText={'Cancel and Quit'}
      hasBackButton={true}
    />
  );
};
