import { useState } from 'react';
import './ModifyProfile.css';
import { useMutation } from '@apollo/client';
import Loading from './common/Loading';
import { Formik, Form } from 'formik';
import { profileDataValidationSchema } from './helpers/formikValidationSchema';
import PersonalInformationForm from './PersonalInformationForm';
import CompanyInformationForm from './CompanyInformationForm';
import { ModifyProfileBottomNav } from './ModifyProfileBottomNav';
import RegistrationHeader from './RegistrationHeader';
import { useAppFlowContext } from '../context/AppFlowProvider';
import { RegistrationModal } from './RegistrationModal';
import { CustomRegistrationHeaderType, IUser } from './Types';
import { UPDATE_CURRENT_USER } from './common/GraphQLMutations';
import { Alert } from '@mui/material';

const ModifyProfile = (): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { setProfileCompleted, currentUser, setCurrentUser } =
    useAppFlowContext();
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [billingError, setBillingError] = useState<boolean>(false);
  const [billingErrorMessage, setBillingErrorMessage] = useState<string>(
    'Error encountered during registration. Please try again.'
  );

  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER, {
    onCompleted: (data) => {
      setDataLoading(false);
      setProfileCompleted(true);
    },
    onError: (error) => {
      setBillingErrorMessage(error.message);
      setBillingError(true);
      setDataLoading(false);
    },
  });

  const flipModal = () => setShowModal((current) => !current);

  const formikOnSubmit = (values: IUser) => {
    setDataLoading(true);
    const updateEmail: boolean =
      currentUser?.email === values.email ? false : true;
    setCurrentUser(values);
    updateCurrentUser({
      variables: {
        updateEmail: updateEmail,
        user_metadata: { use_mfa: false, blocked: false },
        data: {
          email: values?.email,
          profile: values?.profile,
          company: values?.company,
        },
      },
    });
  };

  if (dataLoading) return <Loading></Loading>;

  if (!currentUser) {
    return <div />;
  }

  return (
    <div className='container'>
      <div className='center'>
        <RegistrationHeader page={CustomRegistrationHeaderType.Profile} />
        {billingError && (
          <Alert
            style={{ marginBottom: '0px', marginTop: '5px' }}
            severity='error'
            onClose={() => {
              setBillingError(false);
            }}>
            {billingErrorMessage}
            <br />
            <a
              style={{ color: 'inherit' }}
              href='mailto:admin@integratedroadways.com'>
              If the problem persists, contact support at
              admin@integratedroadways.com
            </a>
          </Alert>
        )}
        <Formik
          id='profileForm'
          initialValues={currentUser}
          enableReinitialize={true}
          validationSchema={profileDataValidationSchema}
          onSubmit={formikOnSubmit}>
          {(formik) => {
            return (
              <ProfileForm
                user={currentUser}
                flipModal={flipModal}
                formik={formik}
              />
            );
          }}
        </Formik>
        <RegistrationModal showModal={showModal} continueHandler={flipModal} />
      </div>
    </div>
  );
};

function ProfileForm({ user, flipModal, formik }: any) {
  return (
    <Form>
      <div className='user-details'>
        <PersonalInformationForm {...user} />
        <CompanyInformationForm />
      </div>
      <ModifyProfileBottomNav
        disableSubmit={!formik.isValid}
        cancelOnClickHandler={flipModal}
        submitText='Save and Continue'
        cancelText='Cancel and Quit'
      />
    </Form>
  );
}

export default ModifyProfile;
