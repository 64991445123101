import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button
      onClick={() => {
        logout({ returnTo: window.location.origin });
      }}>
      <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
        <path
          fill='currentColor'
          d='M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z'
        />
      </svg>
    </button>
  );
};

export default LogoutButton;
