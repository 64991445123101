// import { useMutation } from '@apollo/client';
import {
  Collapse,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { useEffect, useState } from 'react';
import { Button } from './common/buttons/Button';
import { CustomIcon, CustomIconType } from './common/icons/Icons';
import { DevicesListModal } from './DevicesListModal';
// import { AlertBanner } from './common/AlertBanner';
import './DevicesList.css';
import { Device } from './Types';
// import { DELETE_CURRENT_USER_DEVICE } from './common/GraphQLMutations';

export const DeviceIcon = (name: String) => {
  return (
    <>
      {name.toLowerCase().includes('mobile') ? (
        <CustomIcon iconType={CustomIconType.MobileDeviceIcon} rawSvg />
      ) : (
        <CustomIcon iconType={CustomIconType.DesktopDeviceIcon} rawSvg />
      )}
    </>
  );
};

export const DeviceLogoutButton = (props: any) => {
  const [showModal, setShowModal] = useState(false);
  const flipModal = () => setShowModal((current) => !current);

  return (
    <>
      <Button
        onClickHandler={() => flipModal()}
        text='Unlink'
        type='button'
        className='logout-button'
      />
      <DevicesListModal
        key={props.id + 'modal'}
        showModal={showModal}
        cancelOnClickHandler={flipModal}
        continueHandler={async () => {
          await props.continueHandler(props.id);
          flipModal();
        }}
        name={props.name}
        loading={props.loading}></DevicesListModal>
    </>
  );
};

interface IDevicesListProps {
  devices: Device[];
}

const DevicesList = (props: IDevicesListProps) => {
  const [devices, setDevices] = useState<any[]>([]);
  // const [deleteFailed, setDeleteFailed] = useState(false);

  // const [deleteDevice, { loading: deleteLoading }] = useMutation(
  //   DELETE_CURRENT_USER_DEVICE,
  //   {
  //     onError: () => {
  //       setDeleteFailed(true);
  //     },
  //   }
  // );

  // const handleRemoveDevice = async (device_id: String) => {
  //   const response = await deleteDevice({
  //     variables: { device_id: device_id },
  //   });
  //   if (response.data?.deleteCurrentUserDeviceCredentials?.success) {
  //     setDevices((prev) => [...prev.filter((i) => i.id !== device_id)]);
  //     setDeleteFailed(false);
  //   } else {
  //     setDeleteFailed(true);
  //   }
  // };

  useEffect(() => {
    if (!devices.length && !!props.devices.length) {
      const mappedDevices: any[] = [];

      props.devices.forEach((device: Device) => {
        const exists = mappedDevices.findIndex(
          (mappedDevice) => mappedDevice.name === device.device_name
        );

        if (exists === -1) {
          mappedDevices.push({
            id: device.id,
            name: device.device_name,
            last_used: new Date(device.last_used),
          });
        } else {
          const lastUsed: Date = new Date(device.last_used);
          if (mappedDevices[exists].last_used < lastUsed) {
            mappedDevices[exists] = {
              id: device.id,
              name: device.device_name,
              last_used: lastUsed,
            };
          }
        }
      });

      mappedDevices.sort((a: any, b: any) => {
        return b.last_used - a.last_used;
      });

      setDevices(mappedDevices);
    }
  }, [devices.length, props.devices]);

  return (
    <>
      {/* {deleteFailed && (
        <AlertBanner
          text='Device unlink was unsuccessful.'
          severity='error'
          style={{ marginBottom: '0px', marginTop: '20px' }}
        />
      )} */}
      <List>
        <TransitionGroup>
          {devices.map((device) => (
            <Collapse key={device.id}>
              <ListItem
              // secondaryAction={
              //   <DeviceLogoutButton
              //     name={device.name}
              //     id={device.id}
              //     continueHandler={handleRemoveDevice}
              //     loading={deleteLoading}
              //   />
              // }
              >
                <ListItemAvatar>{DeviceIcon(device.name)}</ListItemAvatar>
                <ListItemText
                  primary={device.name}
                  secondary={device.last_used.toDateString()}
                />
              </ListItem>
            </Collapse>
          ))}
        </TransitionGroup>
      </List>
    </>
  );
};

export default DevicesList;
