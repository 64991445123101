export const formatPrice = (price: number) => {
  const formatted = Number(price ? price : 0).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return `${formatted} mo`;
};

export const currentlyActive = (lastLogin: string) => {
  const today = new Date();
  const thirtyDaysAgo = new Date(new Date().setDate(today.getDate() - 30));
  if (thirtyDaysAgo < new Date(lastLogin)) {
    return true;
  } else {
    return false;
  }
};

export const cypressUser = (email: any) => {
  const cytpressUser: boolean = email.endsWith('cypresstestuser.com');
  return cytpressUser;
};
