import { LinearProgress } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Button } from '../buttons/Button';

export interface IHistoryState {
  pathName: string;
  state: any;
}
//the histroy.push function can take a second argument for state, this accounts for that
export interface IBasicWidgetProps {
  title: string;
  data: string;
  dataFormatting: string;
  footerText: string;
  footerLink: string | IHistoryState;
  loading?: boolean;
}

export const BasicWidget = (props: IBasicWidgetProps) => {
  const history = useHistory();
  return (
    <div className='card'>
      <div className='card-top'>
        <div className='card-info'>
          <p>{props.title}</p>
          <h5>{props.data}</h5>
        </div>
      </div>
      <div className='card-footer'>
        {props.loading && (
          <LinearProgress />
        )}
        <Button
          className='card-footer-button'
          onClickHandler={() =>
            typeof props.footerLink === 'string'
              ? history.push(props.footerLink)
              : history.push(props.footerLink.pathName, props.footerLink.state)
          }
          text={props.footerText}></Button>
      </div>
    </div>
  );
};
