import { useState } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_USERS, GET_USER_TOTALS } from './common/GraphQLQueries';
import {
  DataGrid,
  GridRowParams,
  getGridStringOperators,
} from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import BlockedStatusCell from './common/BlockedStatusCell';
import { currentlyActive } from './helpers/helperFunctions';
import './Users.css';
import { Breadcrumbs } from './common/Breadcrumbs';
import { useAppFlowContext } from '../context/AppFlowProvider';

const Users = () => {
  const { setErrorMessage } = useAppFlowContext();
  const rowsPerPageOptions = [5, 10, 20, 50];
  const [pagination, setPagination] = useState(1);
  const [userRows, setUserRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(rowsPerPageOptions[0]);
  const [currentSort, setCurrentSort] = useState({
    field: null,
    sort: null,
  });
  const [currentFilter, setCurrentFilter] = useState({
    field: null,
    filter: null,
  });

  const history = useHistory();

  const [get_total, { loading: totalLoading }] = useLazyQuery(GET_USER_TOTALS, {
    onCompleted: (result: any) => {
      setPagination(result.userTotals.registered_users);
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  const mappedResult = (result: any) => {
    return result.users.map((user: any) => ({
      id: user.user_id,
      subscription_id: user.customerInfo?.subscription?.id,
      name: user.name,
      email: user.email,
      active: currentlyActive(user.last_login) ? 'Yes' : 'No',
      last_login: new Date(user.last_login).toLocaleDateString(),
      subscription: user.customerInfo?.subscription?.tier?.name
        ? user.customerInfo.subscription.tier.name
        : 'No Subscription',
      blocked: user.user_metadata?.blocked || false,
    }));
  };

  const { loading, fetchMore } = useQuery(GET_USERS, {
    variables: {
      page: 0,
      per_page: pageSize,
      field: null,
      sort: null,
      filter: null,
      filter_field: null,
    },
    onCompleted: (result: any) => {
      setUserRows(mappedResult(result));
      get_total();
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  const handlePageChange = (params: any) => {
    setPageLoading(true);
    setCurrentPage(parseInt(params));
    fetchMore({
      variables: {
        page: parseInt(params),
        per_page: pageSize,
        field: currentSort.field,
        sort: currentSort.sort,
        filter: currentFilter.filter,
        filter_field: currentFilter.field,
      },
      updateQuery: (prevResult: any, { fetchMoreResult }: any) => {
        setUserRows([]);
        setPageLoading(false);
        return setUserRows(mappedResult(fetchMoreResult));
      },
    });
  };

  const handleSortModelChange = (params: any) => {
    setPageLoading(true);
    let field: any = null;
    let sort: any = null;
    if (params[0]) {
      field = params[0].field === 'active' ? 'last_login' : params[0].field;
      sort = params[0].sort === 'asc' ? '1' : '-1';
    }
    setCurrentSort({ field: field, sort: sort });
    fetchMore({
      variables: {
        page: currentPage,
        per_page: pageSize,
        field: field,
        sort: sort,
        filter: currentFilter.filter,
        filter_field: currentFilter.field,
      },
      updateQuery: (prevResult: any, { fetchMoreResult }: any) => {
        setUserRows([]);
        setPageLoading(false);
        return setUserRows(mappedResult(fetchMoreResult));
      },
    });
  };

  const onFilterChange = (params: any) => {
    setPageLoading(true);
    let field: any = null;
    let filter: any = null;
    if (params.items[0].value && params.items[0].columnField) {
      field = params.items[0].columnField;
      filter = params.items[0].value;
      params.items[0].value === '' && (filter = null);
      if (field === 'name' || field === 'email') {
        filter = filter + '*';
      }
      if (field === 'blocked') {
        field = 'user_metadata.blocked';
      }
    }
    setCurrentFilter({ field: field, filter: filter });
    fetchMore({
      variables: {
        page: currentPage,
        per_page: pageSize,
        field: currentSort.field,
        sort: currentSort.sort,
        filter: filter,
        filter_field: field,
      },
      updateQuery: (prevResult: any, { fetchMoreResult }: any) => {
        setUserRows([]);
        setPageLoading(false);
        return setUserRows(mappedResult(fetchMoreResult));
      },
    });
  };
  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['startsWith'].includes(value)
  );
  return (
    <>
      <Breadcrumbs
        primary='Admin'
        current='Users'
        primaryClickHandler={() => history.push('/admin/dashboard')}
      />
      <div className='page-title'>Users</div>
      <div className='users-table-container'>
        <DataGrid
          autoHeight
          columns={[
            {
              headerName: '',
              field: 'subscription_id',
              hide: true,
              filterable: false,
              sortable: false,
            },
            {
              headerName: 'Name',
              field: 'name',
              minWidth: 200,
              filterable: true,
              sortable: true,
              filterOperators,
            },
            {
              headerName: 'Email',
              field: 'email',
              minWidth: 200,
              filterable: true,
              sortable: true,
              filterOperators,
            },
            {
              headerName: 'Currently Active',
              field: 'active',
              minWidth: 150,
              sortable: true,
              filterable: false,
            },
            {
              headerName: 'Last Login',
              field: 'last_login',
              minWidth: 100,
              sortable: true,
              filterable: false,
            },
            {
              headerName: 'Subscription',
              field: 'subscription',
              type: 'string',
              flex: 1,
              minWidth: 100,
              maxWidth: 150,
              sortable: false,
              filterable: false,
            },
            {
              headerName: 'Disable',
              field: 'blocked',
              type: 'boolean',
              filterable: true,
              sortable: false,
              renderCell: (params: any) => {
                return (
                  <BlockedStatusCell
                    user_id={params.id}
                    subscription_id={params.row.subscription_id}
                    value={params.value}
                  />
                );
              },
            },
          ]}
          rows={userRows}
          rowHeight={70}
          rowCount={pagination}
          pageSize={pageSize}
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          paginationMode='server'
          sortingMode='server'
          onSortModelChange={handleSortModelChange}
          filterMode='server'
          onFilterModelChange={onFilterChange}
          loading={loading || totalLoading || pageLoading}
          onPageChange={handlePageChange}
          onRowDoubleClick={(params: GridRowParams) =>
            history.push(`/admin/users/${params.id}`)
          }
          sx={{
            '.MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
          }}
        />
      </div>
    </>
  );
};

export default Users;
