import { GET_TOTAL_USERS } from './common/GraphQLQueries';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { Button } from './common/buttons/Button';
import { LinearProgress } from '@mui/material';
import { useAppFlowContext } from '../context/AppFlowProvider';

export const UserCountPanel = (): JSX.Element => {
  const { setErrorMessage } = useAppFlowContext();
  const [registeredUsers, setRegisteredUsers] = useState('--');
  const [activeUsers, setActiveUsers] = useState('--');
  const [activeLogins, setActiveLogins] = useState('--');

  const history = useHistory();

  const { loading: usersLoading } = useQuery(GET_TOTAL_USERS, {
    onCompleted: (result) => {
      setRegisteredUsers(result.userTotals.registered_users);
      setActiveUsers(result.userTotals.active_users);
      setActiveLogins(result.userTotals.active_logins);
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  return (
    <div className='card-large-outside'>
      <div className='card-large'>
        <div className='card-top-large'>
          <div className='card-info'>
            <p>Registered Users</p>
            <h5>{registeredUsers}</h5>
          </div>
        </div>
        <div className='card-top-large'>
          <div className='card-info'>
            <p>Month to Date Active Users</p>
            <h5>{activeUsers}</h5>
          </div>
        </div>
        <div className='card-top-large'>
          <div className='card-info'>
            <p>Daily Active Users</p>
            <h5>{activeLogins}</h5>
          </div>
        </div>
      </div>
      <div className='card-footer'>
        {usersLoading && <LinearProgress />}
        <Button
          className='card-footer-button'
          onClickHandler={() => history.push('/admin/users')}
          text='View More'
        />
      </div>
    </div>
  );
};
