import { useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import Loading from './common/Loading';
import { useAuth0 } from '@auth0/auth0-react';
import { GET_DASHBOARD_URL } from './common/GraphQLQueries';
import { useAppFlowContext } from '../context/AppFlowProvider';
import { useLocation } from 'react-router-dom';

const QuickSightDashboard = () => {
  const { setErrorMessage } = useAppFlowContext();
  let location = useLocation();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const page = new URLSearchParams(location.search).get('page');

  useEffect(() => {
    if (!isAuthenticated) {
      loginWithRedirect({ page: page });
    }
  }, [isAuthenticated, loginWithRedirect, page]);

  const { loading, data } = useQuery(GET_DASHBOARD_URL, {
    fetchPolicy: 'network-only',
    onError: () => {
      if (isAuthenticated) {
        setErrorMessage(true);
      }
    },
  });

  const dashboardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!loading && isAuthenticated) {
      const options = {
        url: data.dashboardEmbedUrl.EmbedUrl,
        container: dashboardRef.current,
        scrolling: 'no',
        height: 'AutoFit',
        loadingHeight: '700px',
        width: '100%',
        locale: 'en-US',
        footerPaddingEnabled: true,
      };
      QuickSightEmbedding.embedDashboard(options);
    }
  }, [data, loading, isAuthenticated, loginWithRedirect, page]);

  if (loading) return <Loading></Loading>;

  return (
    <>
      <div>
        <div ref={dashboardRef} />
      </div>
    </>
  );
};

export default QuickSightDashboard;
