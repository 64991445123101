import './Button.css';

export interface IButtonProps {
  text?: string;
  onClickHandler?: () => void;
  className?: string;
  icon?: any;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

export const Button = (props: IButtonProps): JSX.Element => {
  return (
    <button
      disabled={props.disabled}
      className={props.disabled ? 'disabled-btn' : props.className}
      onClick={props.onClickHandler}
      type={props.type}>
      {props.icon}
      {props.text}
    </button>
  );
};

export const AcceptButton = (props: IButtonProps): JSX.Element => {
  return (
    <Button
      className={props.disabled ? 'disabled-btn' : 'big-blue-btn'}
      onClickHandler={props.onClickHandler}
      icon={props.icon}
      text={props.text}
      disabled={props.disabled}
      type={props.type}
    />
  );
};

export const DeclineButton = (props: IButtonProps): JSX.Element => {
  return (
    <Button
      className={props?.disabled ? 'disabled-btn' : 'red-btn'}
      onClickHandler={props.onClickHandler}
      icon={props.icon}
      text={props.text}
      disabled={props.disabled}
      type={props.type}
    />
  );
};
