import Modal from '@mui/material/Modal';

interface IModalProps {
  open: boolean;
  children: JSX.Element;
  onClose?: () => void;
}

export const MUIModal = (props: IModalProps): JSX.Element => {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      {props.children}
    </Modal>
  );
};
