export interface IUser {
  user_id: string;
  email: string;
  last_login: string;
  roles: Role[];
  customerInfo: CustomerInfo;
  profile: CustomerProfile;
  company: CompanyProfile;
  settings: Settings;
  devices: Device[];
  user_metadata: Auth0MetaData;
}

export interface Auth0MetaData {
  blocked?: boolean;
  use_mfa?: boolean;
}

export interface Role {
  id: string;
  name: string;
  description: string;
}

interface CustomerInfo {
  customer_id: string;
  subscription_id: string;
  subscription: Subscription;
  invoices: [Invoice];
  latest_invoice: Invoice;
}

interface Address {
  street: string;
  city: string;
  state: string;
  zip_code: string;
}

interface CustomerProfile {
  first_name: string;
  last_name: string;
  phone_number: string;
  address: Address;
}

interface CompanyProfile {
  name: string;
  email: string;
  phone_number: string;
  address: Address;
}

interface Settings {
  use_mfa: boolean;
  profile_complete: boolean;
  billing_complete: boolean;
}

export enum CustomRegistrationHeaderType {
  Profile,
  Billing,
}

export interface IDailyStatsData {
  date: string;
  logins: number;
}

export interface IExpenseData {
  start: string;
  end: string;
  total: string;
}

export interface IIncomeData {
  start: string;
  end: string;
  total: string;
}

export interface Device {
  id: string;
  device_name: string;
  last_used: string;
}

export enum ROLE {
  ADMIN = 'admin',
}
export interface SubscriptionTier {
  name: string;
  id: string;
  description: string;
  price: number;
}

export interface Invoice {
  payment: Payment;
  end_date: number;
}

export interface Payment {
  card_details: CardDetails;
}

export interface CardDetails {
  brand: string;
  exp_month: number;
  exp_year: number;
  last4: string;
}

export interface Subscription {
  tier: SubscriptionTier;
  id: string;
}

export interface QuickSightDashboardSummary {
  DashboardId: string;
  Name: string;
  CreatedTime: string;
  LastUpdatedTime: string;
  PublishedVersionNumber: number;
  LastPublishedTime: string;
  Active: boolean;
}
