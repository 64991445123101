import React, { useEffect } from 'react';
import Header from './common/Header';
import Sidebar from './common/Sidebar';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_CURRENT_USER, GET_TOKEN_LIFETIME } from './common/GraphQLQueries';
import { cypressUser } from './helpers/helperFunctions';
import Routing from './Routing';
import { useAppFlowContext } from '../context/AppFlowProvider';
import Loading from './common/Loading';
import { Alert } from '@mui/material';
import { getConfig } from '../config';

const Shell = () => {
  const config = getConfig();
  const { isAuthenticated, isLoading } = useAuth0();
  const queryParams = new URLSearchParams(window.location.search);
  const errorDescription = queryParams.get('error_description');
  const history = useHistory();
  const {
    isRegistered,
    isCustomerLoaded,
    setIsCustomerLoaded,
    profileCompleted,
    setProfileCompleted,
    billingCompleted,
    setBillingCompleted,
    currentUser,
    setCurrentUser,
    isAdmin,
    setPaymentFailed,
    setSqueezePlan,
    setStripeCustomerId,
    setEmailVerified,
    emailVerified,
    errorMessage,
    setErrorMessage,
    currentRoute,
    setTokenLifeTime,
    tokenLifeTime,
  } = useAppFlowContext();

  const [getCurrentUser, { data, loading }] = useLazyQuery(GET_CURRENT_USER, {
    onCompleted: () => {
      setCurrentUser(data.currentUser);
      setStripeCustomerId(data.currentUser.customerInfo.customer_id);
      setProfileCompleted(data.currentUser.settings.profile_complete);
      setBillingCompleted(data.currentUser.settings.billing_complete);
      setIsCustomerLoaded(true);
      const status = data.currentUser?.customerInfo?.subscription?.status;
      setEmailVerified(data.currentUser?.email_verified);
      if (
        status === 'past_due' ||
        status === 'unpaid' ||
        status === 'incomplete'
      ) {
        setPaymentFailed(true);
        history.push('/billing');
      }
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  const [getTokenLifetime] = useLazyQuery(GET_TOKEN_LIFETIME, {
    variables: {
      clientId: config.clientId,
    },
    onCompleted: (result) => {
      setTokenLifeTime(result);
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  useEffect(() => {
    if (errorDescription) {
      history.push('/error?error_description=' + errorDescription);
    }
  }, [errorDescription, history]);

  useEffect(() => {
    if (isAuthenticated) {
      if (!currentUser) {
        getCurrentUser();
      }
      if (!tokenLifeTime) {
        getTokenLifetime();
      } else {
        sessionStorage.removeItem('idleTimer');
        sessionStorage.setItem('idleTimer', new Date().toISOString());
        if (!localStorage.getItem('initialLogin')) {
          localStorage.setItem('initialLogin', new Date().toISOString());
        }
      }
    }
  }, [
    isAuthenticated,
    currentUser,
    getCurrentUser,
    tokenLifeTime,
    getTokenLifetime,
  ]);

  useEffect(() => {
    if (isAuthenticated && currentUser) {
      if (isCustomerLoaded && !profileCompleted) {
        history.push('/register/profile');
      } else if (isCustomerLoaded && !billingCompleted) {
        if (cypressUser(currentUser?.email)) {
          setBillingCompleted(true);
          setEmailVerified(true);
          history.push('/register-email');
        } else if (isAdmin && currentRoute === '/register/profile') {
          history.push('/');
        } else if (!isAdmin) {
          history.push('/register/billing');
        }
      } else if (!emailVerified && billingCompleted && profileCompleted) {
        history.push('/register-email');
      }
    }
  }, [
    emailVerified,
    isAuthenticated,
    currentUser,
    profileCompleted,
    billingCompleted,
    isAdmin,
    history,
    setBillingCompleted,
    setProfileCompleted,
    isCustomerLoaded,
    currentRoute,
    setEmailVerified,
  ]);

  useEffect(() => {
    const plan = sessionStorage.getItem('plan');
    if (plan) {
      setSqueezePlan(plan);
      sessionStorage.removeItem('plan');
    }
  }, [setSqueezePlan]);

  if (isLoading || loading) return <Loading></Loading>;

  return (
    <div className='App'>
      <div>
        <Header showNavIcons={isRegistered} />
        {errorMessage && (
          <Alert
            style={{
              marginLeft: '215px',
              marginTop: '40px',
              marginRight: '40px',
            }}
            severity='error'
            onClose={() => {
              setErrorMessage(false);
            }}>
            {
              'An error has occurred. Please try again later. If the issue persists, please contact us.'
            }
            <br />
            <a
              style={{ color: 'inherit' }}
              href='mailto:admin@integratedroadways.com'>
              Contact Support Here
            </a>
          </Alert>
        )}
        <div className='container'>
          {isRegistered && <Sidebar />}
          <Routing />
        </div>
      </div>
    </div>
  );
};

export default Shell;
