import { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useHistory,
} from 'react-router-dom';
import QuickSightDashboard from './QuickSightDashboard';
import ModifyProfile from './ModifyProfile';
import { useAppFlowContext } from '../context/AppFlowProvider';
import { useAuth0 } from '@auth0/auth0-react';
import Error from './common/Error';
import { ModifyBilling } from './ModifyBilling';
import Users from './Users';
import AdminDashboard from './AdminDashboard';
import Activity from './Activity';
import UserDashboard from './UserDashboard';
import MyInfo from './MyInfo';
import { MonthToDateExpenses } from './MonthToDateExpenses';
import StripeCustomerPortal from './StripeCustomerPortal';
import { PrivateRoute } from './common/PrivateRoute';
import { ROLE } from './Types';
import { MonthToDateIncome } from './MonthToDateIncome';
import EmailVerified from './EmailVerified';
import RegisterEmail from './RegisterEmail';
import { cypressUser } from './helpers/helperFunctions';

const Routing = () => {
  const { isAuthenticated, logout } = useAuth0();
  const {
    isRegistered,
    isAdmin,
    emailVerified,
    setErrorMessage,
    errorMessage,
    currentUser,
    setCurrentRoute,
    currentRoute,
    tokenLifeTime,
  } = useAppFlowContext();

  let location = useLocation();

  const history = useHistory();

  const checkIdleLogin = () => {
    const userTimeOut: any = sessionStorage.getItem('idleTimer');
    const idle_seconds: any =
      Math.abs(new Date(userTimeOut)?.getTime() - new Date().getTime()) / 1000;
    if (
      tokenLifeTime?.getClient?.refresh_token?.idle_token_lifetime <
        idle_seconds &&
      userTimeOut
    ) {
      localStorage.removeItem('initialLogin');
      sessionStorage.removeItem('idleTimer');
      logout();
    } else {
      sessionStorage.setItem('idleTimer', new Date().toISOString());
    }
  };

  const checkInitialLogin = () => {
    const initialLogin: any = localStorage.getItem('initialLogin');
    const lifetime_seconds: any =
      Math.abs(new Date(initialLogin)?.getTime() - new Date().getTime()) / 1000;
    if (
      tokenLifeTime?.getClient?.refresh_token?.token_lifetime <
        lifetime_seconds &&
      initialLogin
    ) {
      localStorage.removeItem('initialLogin');
      sessionStorage.removeItem('idleTimer');
      logout();
    }
  };

  history.listen((location) => {
    if (currentRoute !== location.pathname && errorMessage !== false) {
      setErrorMessage(false);
    }
    if (currentRoute !== location.pathname && tokenLifeTime) {
      checkIdleLogin();
      checkInitialLogin();
    }
    setCurrentRoute(location.pathname);
  });

  useEffect(() => {
    const plan = new URLSearchParams(location.search).get('plan');
    if (plan) {
      sessionStorage.setItem('plan', plan);
    }
  }, [location.search]);

  const success = new URLSearchParams(location.search).get('success');
  const message = new URLSearchParams(location.search).get('message');

  if (success && message) {
    return (
      <Switch>
        <Route
          component={() => (
            <EmailVerified success={success} message={message} />
          )}
        />
      </Switch>
    );
  }

  return (
    <div className='content-area'>
      {!isAuthenticated && (
        <Switch>
          <Route path='/error' component={Error} />
          <Route path='/' component={QuickSightDashboard} />
        </Switch>
      )}
      {isAuthenticated && !isRegistered && (
        <Switch>
          <Route path='/register/profile' component={ModifyProfile} />
          <Route path='/register/billing' component={ModifyBilling} />
        </Switch>
      )}
      {isAuthenticated &&
        isRegistered &&
        !emailVerified &&
        !cypressUser(currentUser?.email) && (
          <Switch>
            <Route path='/register-email' component={RegisterEmail} />
          </Switch>
        )}
      {isAuthenticated &&
        isRegistered &&
        (emailVerified || cypressUser(currentUser?.email)) && (
          <Switch>
            <Route
              exact
              path='/'
              render={() => {
                return isAdmin ? (
                  <Redirect to='/admin/dashboard' />
                ) : (
                  <Redirect to='/dashboard' />
                );
              }}
            />
            <PrivateRoute
              exact
              path='/admin/dashboard'
              roles={[ROLE.ADMIN]}
              component={AdminDashboard}
            />
            <PrivateRoute
              exact
              path='/admin/users'
              roles={[ROLE.ADMIN]}
              component={Users}
            />
            <PrivateRoute
              path='/admin/users/:id'
              roles={[ROLE.ADMIN]}
              component={UserDashboard}
            />
            <PrivateRoute
              exact
              path='/admin/dashboard/income'
              roles={[ROLE.ADMIN]}
              component={MonthToDateIncome}
            />
            <PrivateRoute
              exact
              path='/admin/dashboard/expenses'
              roles={[ROLE.ADMIN]}
              component={MonthToDateExpenses}
            />

            <Route path='/dashboard' component={QuickSightDashboard} />
            <Route path='/account' component={UserDashboard} />
            <Route path='/my-info' component={MyInfo} />
            <Route path='/billing' component={StripeCustomerPortal} />
            <Route path='/activity' component={Activity} />
          </Switch>
        )}
    </div>
  );
};

export default Routing;
