import { GET_DAILY_STATS } from './common/GraphQLQueries';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { DailyActiveUsersChart, filterDays } from './DailyActiveUsersChart';
import {
  filterMonths,
  MonthlyActiveUsersChart,
} from './MonthlyActiveUsersChart';
import { filterWeeks, WeeklyActiveUsersChart } from './WeeklyActiveUsersChart';
import { useAppFlowContext } from '../context/AppFlowProvider';
import { LinearProgress } from '@mui/material';

export const LoginStatsPanel = (): JSX.Element => {
  const [dailyUsersData, setDailyUsersData] = useState();
  const [monthlyUsersData, setMonthlyUsersData] = useState([]);
  const [weeklyUserData, setWeeklyUserData] = useState({
    values: [],
    format: [],
    data: [],
  });
  const { setErrorMessage } = useAppFlowContext();

  const { loading: statsLoading } = useQuery(GET_DAILY_STATS, {
    onCompleted: (data) => {
      setDailyUsersData(filterDays(data.dailyStats) as any);
      setMonthlyUsersData(filterMonths(data.dailyStats) as any);
      setWeeklyUserData(filterWeeks(data.dailyStats) as any);
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  return (
    <>
      <div className='left-graph'>
        <div className='graph-top'>Daily Login Count</div>
        <div className='left-graph-chart daily-chart'>
          <DailyActiveUsersChart dailyData={dailyUsersData} />
        </div>
        {statsLoading && <LinearProgress />}
      </div>
      <div className='left-graph'>
        <div className='graph-top'>Weekly Login Count</div>
        <div className='left-graph-chart weekly-chart'>
          <WeeklyActiveUsersChart weeklyData={weeklyUserData} />
        </div>
        {statsLoading && <LinearProgress />}
      </div>
      <div className='right-graph'>
        <div className='graph-top'>Monthly Login Count</div>
        <div className='right-graph-chart monthly-chart'>
          <MonthlyActiveUsersChart
            monthlyData={monthlyUsersData}
            loading={statsLoading}
          />
        </div>
        {statsLoading && <LinearProgress />}
      </div>
    </>
  );
};
