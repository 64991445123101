import { useState, useEffect, useContext, createContext } from 'react';
import { IUser, ROLE } from '../components/Types';

export type AppFlowProviderProps = {
  children: any;
};

interface ISubscriptionData {
  clientSecret: string;
}

export type AppFlowContextType = {
  isRegistered: boolean;

  isCustomerLoaded: boolean;
  setIsCustomerLoaded: (isCustomerLoaded: boolean) => void;

  profileCompleted: boolean;
  setProfileCompleted: (profileCompleted: boolean) => void;

  billingCompleted: boolean;
  setBillingCompleted: (billingCompleted: boolean) => void;

  currentUser: IUser | undefined;
  setCurrentUser: (currentUser: IUser) => void;

  tokenLifeTime: any | undefined;
  setTokenLifeTime: (tokenLifeTime: any) => void;

  isAdmin: boolean;
  setIsAdmin: (isAdmin: boolean) => void;

  subscriptionData: ISubscriptionData;
  setSubscriptionData: (subscriptionData: ISubscriptionData) => void;

  paymentFailed: boolean;
  setPaymentFailed: (paymentFailed: boolean) => void;

  emailVerified: boolean;
  setEmailVerified: (emailVerified: boolean) => void;

  squeezePlan: string;
  setSqueezePlan: (squeezePlan: string) => void;

  errorMessage: boolean;
  setErrorMessage: (errorMessage: boolean) => void;

  setupIntentId: string;
  setSetupIntentId: (setupIntentId: string) => void;

  customerPortal: string;
  setCustomerPortal: (customerPortal: string) => void;

  stripeCustomerId: string;
  setStripeCustomerId: (setStripeCustomerId: string) => void;

  currentRoute: string;
  setCurrentRoute: (setCurrentRoute: string) => void;
};

const AppFlowContextMessage = () => {
  console.log('useAppFlowContext must be used within a AppFlowProvider');
};

const AppFlowContext = createContext<AppFlowContextType>({
  isRegistered: false,

  isCustomerLoaded: false,
  setIsCustomerLoaded: (isCustomerLoaded) => AppFlowContextMessage,

  profileCompleted: false,
  setProfileCompleted: (profileCompleted) => AppFlowContextMessage,

  billingCompleted: false,
  setBillingCompleted: (billingCompleted) => AppFlowContextMessage,

  currentUser: undefined,
  setCurrentUser: (currentUser) => AppFlowContextMessage,

  tokenLifeTime: undefined,
  setTokenLifeTime: (tokenLifeTime) => AppFlowContextMessage,

  isAdmin: false,
  setIsAdmin: (isAdmin) => AppFlowContextMessage,

  subscriptionData: { clientSecret: '' },
  setSubscriptionData: (subscriptionData) => AppFlowContextMessage,

  paymentFailed: false,
  setPaymentFailed: (paymentFailed) => AppFlowContextMessage,

  emailVerified: false,
  setEmailVerified: (emailVerified) => AppFlowContextMessage,

  squeezePlan: 'free',
  setSqueezePlan: (squeezePlan) => AppFlowContextMessage,

  errorMessage: false,
  setErrorMessage: (errorMessage) => AppFlowContextMessage,

  customerPortal: '',
  setCustomerPortal: (customerPortal) => AppFlowContextMessage,

  setupIntentId: '',
  setSetupIntentId: (setupIntentId) => AppFlowContextMessage,

  stripeCustomerId: '',
  setStripeCustomerId: (stripeCustomerId) => AppFlowContextMessage,

  currentRoute: '',
  setCurrentRoute: (currentRoute) => AppFlowContextMessage,
});

function AppFlowProvider({ children }: AppFlowProviderProps) {
  const [isRegistered, setIsRegistered] = useState(false);
  const [isCustomerLoaded, setIsCustomerLoaded] = useState(false);
  const [profileCompleted, setProfileCompleted] = useState(false);
  const [billingCompleted, setBillingCompleted] = useState(false);
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [tokenLifeTime, setTokenLifeTime] = useState<any>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState<ISubscriptionData>({
    clientSecret: '',
  });
  const [paymentFailed, setPaymentFailed] = useState<boolean>(false);
  const [emailVerified, setEmailVerified] = useState<boolean>(true);
  const [squeezePlan, setSqueezePlan] = useState<string>('free');
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [setupIntentId, setSetupIntentId] = useState<string>('');
  const [customerPortal, setCustomerPortal] = useState<string>('');
  const [stripeCustomerId, setStripeCustomerId] = useState<string>('');
  const [currentRoute, setCurrentRoute] = useState<string>('');

  const value = {
    isRegistered,
    isCustomerLoaded,
    setIsCustomerLoaded,
    profileCompleted,
    setProfileCompleted,
    billingCompleted,
    setBillingCompleted,
    currentUser,
    setCurrentUser,
    tokenLifeTime,
    setTokenLifeTime,
    isAdmin,
    setIsAdmin,
    subscriptionData,
    setSubscriptionData,
    paymentFailed,
    setPaymentFailed,
    emailVerified,
    setEmailVerified,
    squeezePlan,
    setSqueezePlan,
    errorMessage,
    setErrorMessage,
    setupIntentId,
    setSetupIntentId,
    customerPortal,
    setCustomerPortal,
    stripeCustomerId,
    setStripeCustomerId,
    currentRoute,
    setCurrentRoute,
  };

  useEffect(() => {
    if (profileCompleted && (billingCompleted || isAdmin)) {
      setIsRegistered(true);
    }
  }, [profileCompleted, billingCompleted, isAdmin]);

  useEffect(() => {
    if (currentUser) {
      setIsAdmin(
        currentUser?.roles.some((role) => role.name === ROLE.ADMIN) || false
      );
    }
  }, [currentUser]);

  return (
    <AppFlowContext.Provider value={value}>{children}</AppFlowContext.Provider>
  );
}

function useAppFlowContext() {
  const context = useContext(AppFlowContext);
  if (context === undefined) {
    throw new Error('useAppFlowContext must be used within a AppFlowProvider');
  }
  return context;
}

export { AppFlowProvider, useAppFlowContext };
