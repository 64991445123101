import { IDailyStatsData } from './Types';
import { VictoryChart, VictoryAxis, VictoryArea, VictoryGroup, VictoryTooltip, VictoryVoronoiContainer } from 'victory';
import dayjs from 'dayjs';
import { months } from './helpers/constants';
import { BlueGradient } from './common/buttons/ChartGradients';

export const filterMonths = (data: IDailyStatsData[]) => {
  let accData: any = {};
  let valueCount = 0;

  for (let i = 0; i < data.length; i++) {
    const theDate = new Date(data[i].date);
    const month = theDate.getMonth();

    if (dayjs(theDate).isAfter(dayjs().subtract(12, 'month'))) {
      if (accData[month]) {
        accData[month] = {
          ...accData[month],
          logins: (accData[month].logins += data[i].logins),
        };
      } else {
        accData[month] = {
          value: valueCount,
          month: months[month],
          logins: data[i].logins,
        };
        valueCount++;
      }
    }
  }
  return Object.values(accData);
};

export const MonthlyActiveUsersChart = (props: any) => (
  <>
    <VictoryChart domainPadding={5}
      containerComponent={
        <VictoryVoronoiContainer />
      }>
      <VictoryAxis
        tickValues={props.monthlyData.map((i: any) => i.value)}
        tickFormat={props.monthlyData.map((i: any) => i.month)}
      />
      <VictoryAxis
        style={{
          grid: {
            fill: '#1BA4FF',
            stroke: '#818e99',
            strokeWidth: 0.5,
          },
        }}
        dependentAxis
      />
      <VictoryGroup
        style={{
          data: {
            strokeWidth: 3,
          }
        }}
      >
        <VictoryArea
          data={props.monthlyData} x='value' y='logins'
          labels={({ datum }) => `${datum.logins}`}
          labelComponent={<VictoryTooltip
            pointerOrientation="right"
            dy={0}
            dx={0}
            pointerWidth={25}
            cornerRadius={0}
            centerOffset={{ x: -20 }}
          />}
          style={{
            data: {
              stroke: '#646569',
              fill: 'url(#blueGradient)',
              strokeWidth: 3.0
            }
          }}
        />
      </VictoryGroup>
    </VictoryChart>
    <BlueGradient />
  </>
);
