import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_CURRENT_USER } from './common/GraphQLMutations';
import { Formik, Form } from 'formik';
import Loading from './common/Loading';
import { profileDataValidationSchema } from './helpers/formikValidationSchema';
import PersonalInformationForm from './PersonalInformationForm';
import CompanyInformationForm from './CompanyInformationForm';
import { useAppFlowContext } from '../context/AppFlowProvider';
import { AlertBanner } from './common/AlertBanner';
import { IUser } from './Types';
import { AcceptButton } from '../components/common/buttons/Button';
import { CustomIcon, CustomIconType } from '../components/common/icons/Icons';
import './ModifyProfile.css';


const MyInfo = () => {
  const { currentUser, setCurrentUser } = useAppFlowContext();
  const [updateFailed, setUpdateFailed] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [updateCurrentUser] = useMutation(UPDATE_CURRENT_USER, {
    onCompleted: () => {
      setUpdateSuccess(true);
      setSubmitted(false);
    },
    onError: () => {
      setUpdateFailed(true);
    },
  });

  const formikOnSubmit = (values: IUser) => {
    setUpdateSuccess(false);
    setSubmitted(true);
    const updateEmail: boolean =
      currentUser?.email === values.email ? false : true;
    setCurrentUser(values);
    updateCurrentUser({
      variables: {
        updateEmail: updateEmail,
        data: {
          email: values?.email,
          profile: values?.profile,
          company: values?.company,
        },
      },
    });
  };

  if (!currentUser) return <Loading></Loading>;

  return (
    <div>
      {updateFailed && (
        <AlertBanner
          text='Error saving user. Please refresh and try again.'
          severity='error'
          style={{ position: 'fixed', top: '100px' }}
        />
      )}
      {updateSuccess && (
        <AlertBanner
          text='Information Successfully Saved'
          severity='success'
          style={{ position: 'fixed', top: '100px' }}
        />
      )}
      <div className='page-title'>My Info</div>
      <div className='container'>
        <div>
          <Formik
            id='profileForm'
            initialValues={currentUser}
            enableReinitialize={true}
            validationSchema={profileDataValidationSchema}
            onSubmit={formikOnSubmit}>
            {(formik) => {
              return (
                <Form>
                  <div className='user-details'>
                    <PersonalInformationForm />
                    <CompanyInformationForm />
                  </div>
                  <div className='bottom-nav'>
                    <button
                      className='txtBtn'
                      type='reset'
                      onClick={(e) => formik.resetForm()}>
                      {' '}
                      Cancel Changes
                    </button>
                    <AcceptButton
                      type='submit'
                      disabled={
                        !formik.isValid ||
                        submitted ||
                        formik.values === formik.initialValues
                      }
                      icon={
                        <CustomIcon iconType={CustomIconType.SaveIcon} rawSvg />
                      }
                      text='Save Changes'
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default MyInfo;
