import { useAuth0 } from '@auth0/auth0-react';
import { StyledCustomIcon, CustomIconType } from './common/icons/Icons';
import './EmailVerified.css';

interface RegisterEmailProps {
  success: string;
  message: any;
}

const RegisterEmail = (props: RegisterEmailProps) => {
  const { isAuthenticated } = useAuth0();

  return (
    <>
      {props.success === 'true' && (
        <div className='verification-check'>
          <StyledCustomIcon
            iconType={CustomIconType.CheckCircleOutline}
            style={{
              marginTop: '40px',
              width: '100px',
              height: '100px',
              color: 'green',
            }}
          />
          <div className='verification-title'>Email Verified</div>
          <div className='verification-body'>
            Your email address was successfully verified.
          </div>
          {isAuthenticated ? (
            <div className='verification-body'>
              Click here to see your <a href='/'>Dashboard</a>.
            </div>
          ) : (
            <div className='verification-body'>
              Click here to <a href='/'>Log in</a> now.
            </div>
          )}
        </div>
      )}
      {props.success === 'false' && (
        <div className='verification-check'>
          <StyledCustomIcon
            iconType={CustomIconType.CheckCircleOutline}
            style={{
              marginTop: '40px',
              width: '100px',
              height: '100px',
              color: 'red',
            }}
          />
          <div className='verification-title'>
            Email Verification Not Successful
          </div>
          <div className='verification-body'>{props.message}</div>
        </div>
      )}
    </>
  );
};

export default RegisterEmail;
