import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAppFlowContext } from '../../context/AppFlowProvider';

export const PrivateRoute = ({ component: Component, roles, ...rest }: any) => {

    const { currentUser } = useAppFlowContext();

    return (
        <Route {...rest} render={props => {
            const hasNeededRoles = roles.every((neededRole: string) => {
                return currentUser?.roles.some(role => role.name === neededRole);
            });
            if (!hasNeededRoles) {
                return <Redirect to={{ pathname: '/' }} />
            }

            return <Component {...props} />
        }} />
    )
}