import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/link-context';
import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { getConfig } from '../config';

const AuthorizedApolloProvider = ({ children }: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const config = getConfig();

  const httpLink = createHttpLink({
    uri: config.api.origin,
  });

  const authLink = setContext(async () => {
    const token = await getAccessTokenSilently({
      audience: config.api.audience,
    });
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      addTypename: false
    }),
    connectToDevTools: true,

  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
