import { Breadcrumbs as MUIBreadcrumbs, Typography } from '@mui/material';
import { Button } from './buttons/Button';

interface IBreadcrumbsProps {
  primaryClickHandler: () => void;
  secondaryClickHandler?: () => void;
  primary: string;
  secondary?: string;
  current: string;
}

export const Breadcrumbs = (props: IBreadcrumbsProps): JSX.Element => (
  <MUIBreadcrumbs separator='›' aria-label='breadcrumb' className='breadcrumbs'>
    <Button
      onClickHandler={props.primaryClickHandler}
      text={props.primary}
      className='txtBtn'
    />
    {!!props.secondary && (
      <Button
        onClickHandler={props.secondaryClickHandler}
        text={props.secondary}
        className='txtBtn'
      />
    )}
    <Typography color='text.primary'>{props.current}</Typography>
  </MUIBreadcrumbs>
);
