import { VictoryChart, VictoryAxis, VictoryBar, VictoryVoronoiContainer, VictoryTooltip, VictoryLabel } from 'victory';


export const CurrentMonthExpensesChart = (props: { expenses: number; }) => {
  return (
    <>
      <VictoryChart
        width={300}
        containerComponent={
          <VictoryVoronoiContainer />
        }>
        <VictoryAxis
          tickLabelComponent={(
            <VictoryLabel
              textAnchor="middle"
              x={150}
            />
          )}
          tickValues={[0,
            // 1, 2
          ]}
          tickFormat={['AWS',
            // 'Auth0', 'Stripe'
          ]}
          style={{
            tickLabels: {}
          }}
        />
        <VictoryAxis
          tickFormat={(t) => `$${t.toFixed(2)}`}
          style={{
            grid: { stroke: '#818e99', strokeWidth: 0.5 },
          }}
          dependentAxis
        />
        <VictoryBar
          data={[
            {
              value: 0,
              total: props.expenses,
            },
            // { value: 1, total: 0 },
            // {
            //   value: 2,
            //   total: getThisMonthsExpenses(props.location.state.stripe),
            //   fill: '#B39D12',
            // },
          ]}
          labels={({ datum }) => `$${parseFloat(datum.total).toFixed(2)}`}
          labelComponent={<VictoryTooltip
            pointerOrientation="right"
            dy={0}
            dx={100}
            pointerWidth={25}
            cornerRadius={0}
            centerOffset={{ x: -40 }}
          />}
          style={{
            data: {
              strokeWidth: 1,
              stroke: '#646569',
              fill: 'url(#blueGradient)',
            },

          }}
          x='value'
          y='total'
          barRatio={25}
          alignment='start'
        />
      </VictoryChart>
    </>
  );
};
