import { CircularProgress } from '@mui/material';
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#646569',
    },
  },
});

const Loading = () => (
  <ThemeProvider theme={theme}>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress sx={{ my: 5 }} />
    </div>
  </ThemeProvider>
);

export default Loading;
