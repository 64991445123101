import { SubscriptionCard } from './SubscriptionCard';

interface ISubscriptionSelectProps {
  subscriptions: any;
  currentSubscription: any;
  switchCurrentSub: (sub: any) => void;
}

export const SubscriptionSelectForm = (
  props: ISubscriptionSelectProps
): JSX.Element => {
  return (
    <div className='user-details-card subscription-details-card'>
      <p className='user-details-title'>Your Plan</p>
      <SubscriptionCard
        key={props?.currentSubscription?.name}
        sub={props?.currentSubscription}
        focus={true}
        switchCurrentSub={props.switchCurrentSub}></SubscriptionCard>
      {props.subscriptions.length > 1 && (
        <>
          <p>Or switch to:</p>
          {props.subscriptions
            .filter((sub: any) => sub.name !== props?.currentSubscription?.name)
            .sort((a: any, b: any) => {
              return a.price - b.price;
            })
            .map((sub: any) => (
              <SubscriptionCard
                key={sub.name}
                sub={sub}
                focus={false}
                switchCurrentSub={props.switchCurrentSub}></SubscriptionCard>
            ))}
        </>
      )}
    </div>
  );
};
