import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { VictoryChart, VictoryAxis, VictoryBar, VictoryTooltip } from 'victory';
import { IDailyStatsData } from './Types';

const today = new Date();

dayjs.extend(isBetween);

const getWeeks = () => {
  let tempData: any = [];
  let lastDate = new Date(today);
  for (let i = 6; i >= 0; i--) {
    let firstDate = new Date(dayjs(lastDate).subtract(6, 'd').toDate());
    const value = { first: firstDate, last: lastDate, value: i };
    tempData.unshift(value);

    lastDate = new Date(dayjs(firstDate).subtract(1, 'd').toDate());
  }
  return tempData;
};

export const filterWeeks = (data: IDailyStatsData[]) => {
  const weeks = getWeeks();
  const accData: any = {};
  for (let i = 0; i < data.length; i++) {
    let theDate = new Date(data[i].date);
    for (let j = 0; j < weeks.length; j++) {
      if (dayjs(theDate).isBetween(weeks[j].first, weeks[j].last)) {
        accData[weeks[j].value]
          ? (accData[weeks[j].value].logins += data[i].logins)
          : (accData[weeks[j].value] = {
              value: weeks[j].value + 1,
              logins: data[i].logins,
            });
      }
    }
  }
  const values = weeks.map((i: any) => i.value + 1);
  const format = weeks.map((i: any) => {
    return `${i.first.getMonth() + 1}/${i.first.getDate()}
      -
      ${i.last.getMonth() + 1}/${i.last.getDate()}`;
  });
  return {
    data: Object.values(accData),
    values: values,
    format: format,
  };
};

export const WeeklyActiveUsersChart = (props: any) => (
  <VictoryChart
    domainPadding={{ x: 30 }}
    width={700}
    padding={{ left: 50, top: 10, bottom: 70, right: 50 }}>
    <VictoryAxis
      tickValues={props.weeklyData.values}
      tickFormat={props.weeklyData.format}
      style={{
        tickLabels: {
          color: '#FDA59D',
        },
      }}
    />
    <VictoryAxis
      style={{
        grid: { stroke: '#818e99', strokeWidth: 0.5 },
      }}
      dependentAxis
    />
    <VictoryBar
      data={props.weeklyData.data}
      labels={({ datum }) => `${datum.logins}`}
      labelComponent={
        <VictoryTooltip
          pointerOrientation='right'
          dy={0}
          dx={0}
          pointerWidth={25}
          cornerRadius={0}
          centerOffset={{ x: -20 }}
        />
      }
      x='value'
      y='logins'
      alignment='middle'
      style={{
        data: {
          fill: '#B39D12',
          stroke: '#B39D12',
        },
      }}
    />
  </VictoryChart>
);
