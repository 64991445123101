import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Shell from './components/Shell';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffdb00',
    },
  },
  typography: {
    fontFamily: 'TTLakes-Medium',
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Shell />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
