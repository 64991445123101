import dayjs from 'dayjs';
import { IExpenseData } from './Types';
import { VictoryChart, VictoryAxis, VictoryLegend, VictoryVoronoiContainer, VictoryTooltip, VictoryArea, VictoryGroup } from 'victory';
import { months } from './helpers/constants';
import { BlueGradient } from './common/buttons/ChartGradients';

export const shapeExpenseData = (datas: IExpenseData[][]) =>
  datas.map((data) =>
    data
      .filter((item: any) => item.total !== '0')
      .sort(
        (a: any, b: any) =>
          dayjs(a.start).toDate().getTime() - dayjs(b.start).toDate().getTime()
      )
      .map((item: any) => ({
        ...item,
        total: Number(item.total),
        source: item.total === 0 ? 'stripe' : 'aws',
      }))
  );
//This function takes the expense data, sorts it by start date, and then maps it to numberify the total and add a source for later

export const shapeTableData = (data: IExpenseData[][]) => {
  let accData: any = {};
  let valueCount = 0;

  data.map((d) =>
    d.forEach((item: any) => {
      const month = dayjs(item.start).month();
      if (dayjs(item.start).isAfter(dayjs().subtract(12, 'month'))) {
        if (accData[month]) {
          accData[month] = {
            ...accData[month],
            [item.source]: item.total,
          };
        } else {
          accData[month] = {
            value: valueCount,
            month: month,
            [item.source]: item.total,
          };
          valueCount++;
        }
      }
    })
  );
  //This function builds a new data shape to plug into victory chart
  //The value it used to sort he most recent month to the end
  //The source is used to replace the total and label it

  return accData;
};

export const CurrentYearExpensesChart = (props: any) => {
  const awsData = props.expenses;
  const stripeData: IExpenseData[] = [];
  const reshapedExpenseData = shapeExpenseData([awsData, stripeData]);
  const reshapedTableData = shapeTableData(reshapedExpenseData);

  const tableDataWithoutMissingValues = Object.values(reshapedTableData).map(
    (item: any) => {
      if (!item.aws) {
        return { ...item, aws: 0 };
      }
      if (!item.stripe) {
        return { ...item, stripe: 0 };
      }
      return item;
    }
  );
  //Victory does not handle missing values well. This function fills them in because leaving them out will break

  return (
    <>
      <VictoryChart width={1200}
        containerComponent={
          <VictoryVoronoiContainer />
        }>
        <VictoryLegend x={10} y={0}
          orientation="horizontal"
          gutter={20}
          style={{ border: { stroke: "black" } }}
          colorScale={["#0099FF",
            //  "#B39D12"
          ]}
          data={[
            { name: "AWS" },
            // { name: "Stripe" }
          ]}
        />
        <VictoryAxis
          tickValues={tableDataWithoutMissingValues.map(
            (item: any) => item.value
          )}
          tickFormat={tableDataWithoutMissingValues.map(
            (item: any) => months[item.month]
          )}
        />
        <VictoryAxis
          tickFormat={(t) => `$${t}`}
          style={{
            grid: {
              fill: '#1BA4FF',
              stroke: '#818e99',
              strokeWidth: 0.5,
            },
          }}
          dependentAxis
        />
        <VictoryGroup
          style={{
            data: { strokeWidth: 3 }
          }}
        >
          <VictoryArea
            data={tableDataWithoutMissingValues}
            labels={({ datum }) => `$${parseFloat(datum.aws).toFixed(2)}`}
            labelComponent={<VictoryTooltip
              pointerOrientation="right"
              dy={0}
              dx={0}
              pointerWidth={25}
              cornerRadius={0}
              centerOffset={{ x: -40 }}
            />}
            x='value'
            y='aws'
            style={{
              data: {
                stroke: '#646569',
                fill: 'url(#blueGradient)',
                strokeWidth: 3.0
              }
            }}
          />
        </VictoryGroup>
        {/* <VictoryArea
        data={tableDataWithoutMissingValues}
        labels={({ datum }) => `$${parseFloat(datum.stripe).toFixed(2)}`}
        labelComponent={<VictoryTooltip />}
        x='value'
        y='stripe'
        style={{ data: { stroke: '#B39D12', fill: '#B39D12', strokeWidth: 3.0 } }}
      /> */}
      </VictoryChart>
      <BlueGradient />
    </>
  );
};
