import { useState } from 'react';
import dayjs from 'dayjs';
import { useMutation, useQuery } from '@apollo/client';
import { GET_QUICKSIGHT_DASHBOARDS } from './common/GraphQLQueries';
import { UPDATE_ACTIVE_QUICKSIGHT_DASHBOARD } from './common/GraphQLMutations';
import { QuickSightDashboardSummary } from './Types';
import { useAppFlowContext } from '../context/AppFlowProvider';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  LinearProgress,
} from '@mui/material';
import { Button } from './common/buttons/Button';
import { CustomIcon, CustomIconType } from './common/icons/Icons';
import dashboardThumbnail from '../assets/quickSightThumbnail.svg';
import './DashboardConfiguration.css';

const DashboardConfiguration = () => {
  const [quickSightDashboards, setQuickSightDashboards] = useState<
    QuickSightDashboardSummary[]
  >([]);
  const [activeDashboard, setActiveDashboard] =
    useState<QuickSightDashboardSummary>();
  const { setErrorMessage } = useAppFlowContext();
  const { loading, refetch } = useQuery(GET_QUICKSIGHT_DASHBOARDS, {
    onCompleted: (result: any) => {
      setQuickSightDashboards(result.quickSightDashboards);
      setActiveDashboard(
        result.quickSightDashboards.find(
          (dashboard: QuickSightDashboardSummary) => dashboard.Active
        )
      );
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  const [updateActiveQuickSightDashboard, { loading: updating }] = useMutation(
    UPDATE_ACTIVE_QUICKSIGHT_DASHBOARD,
    {
      onCompleted: (data) => {
        const activeDashboard = quickSightDashboards.find(
          (dashboard: QuickSightDashboardSummary) =>
            dashboard.DashboardId ===
            data.updateActiveQuickSightDashboard.defaultDashboardId
        );
        setActiveDashboard(activeDashboard);
        refetch();
      },
      onError: () => {
        setErrorMessage(true);
      },
    }
  );

  const handleMakeActive = (dashboardId: String) => {
    updateActiveQuickSightDashboard({
      variables: { dashboardId: dashboardId },
    });
  };

  return (
    <>
      <Accordion className='quicksight-dashboard-configuration'>
        <AccordionSummary
          expandIcon={
            <CustomIcon iconType={CustomIconType.DownArrowIcon} rawSvg />
          }
          aria-controls='panel1a-content'
          id='quicksight-dashboard-section'>
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='center'>
            <Grid item>Active QuickSight Dashboard</Grid>
            <Grid item>
              {loading || updating ? (
                <LinearProgress sx={{ width: '300px' }} />
              ) : (
                activeDashboard?.Name
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={5} sx={{ padding: 5 }}>
            {quickSightDashboards?.map((dashboard) => (
              <Grid item key={dashboard.DashboardId}>
                <Box>
                  <Card
                    variant='outlined'
                    className={`dashboard-card ${
                      activeDashboard?.DashboardId === dashboard.DashboardId
                        ? 'active'
                        : ''
                    }`}
                    sx={{ maxWidth: 275, minHeight: 275 }}>
                    {activeDashboard?.DashboardId === dashboard.DashboardId && (
                      <Box
                        sx={{
                          zIndex: 'modal',
                          position: 'absolute',
                          padding: '5px',
                        }}>
                        <Chip label='Active' color='error' />
                      </Box>
                    )}
                    <CardMedia
                      component='img'
                      alt='{dashboard.Name}'
                      height='140'
                      image={dashboardThumbnail}
                    />
                    <CardContent>
                      {dashboard.Name}
                      <br />
                      <Typography variant='caption'>
                        {dayjs(Number(dashboard.LastPublishedTime)).format(
                          'MM/DD/YYYY hh:mm:ss A'
                        )}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {activeDashboard?.DashboardId !==
                        dashboard.DashboardId && (
                        <Button
                          className='big-blue-btn'
                          text='Make Active'
                          disabled={updating}
                          onClickHandler={() => {
                            handleMakeActive(dashboard.DashboardId);
                          }}
                        />
                      )}
                    </CardActions>
                  </Card>
                </Box>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default DashboardConfiguration;
