import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USER_ACTIVITY_DATA } from './common/GraphQLQueries';
import { DataGrid } from '@mui/x-data-grid';
import { useAppFlowContext } from '../context/AppFlowProvider';
import { parseUserAgent } from './helpers/userAgentParser';

const Activity = () => {
  const { setErrorMessage } = useAppFlowContext();
  const rowsPerPageOptions = [5, 10, 20, 50];
  const [userRows, setUserRows] = useState([]);
  const [pageSize, setPageSize] = useState<number>(rowsPerPageOptions[0]);

  const { loading } = useQuery(GET_USER_ACTIVITY_DATA, {
    onCompleted: (result) => {
      setUserRows(
        result.currentUser.activity_data.map(
          (activity_data: any, index: any) => ({
            id: index,
            login_date: new Date(activity_data?.timestamp).toLocaleDateString(),
            login_time: new Date(activity_data?.timestamp).toLocaleTimeString(),
            device: parseUserAgent(activity_data?.user_agent),
          })
        )
      );
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  return (
    <div className='user-activity'>
      <div className='page-title'>Recent Activity</div>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          autoHeight
          columns={[
            {
              headerName: 'Login Date',
              field: 'login_date',
              sortable: false,
              minWidth: 300,
            },
            {
              headerName: 'Login Time',
              field: 'login_time',
              minWidth: 300,
              sortable: false,
            },
            {
              headerName: 'Device',
              field: 'device',
              sortable: false,
              minWidth: 350,
            },
          ]}
          rows={userRows}
          rowHeight={60}
          pageSize={pageSize}
          loading={loading}
          disableSelectionOnClick
          rowsPerPageOptions={rowsPerPageOptions}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          sx={{
            border: 2,
            borderColor: '#e4e4e4',
          }}
          sortModel={[{ field: 'login_date', sort: 'desc' }]}
        />
      </div>
    </div>
  );
};

export default Activity;
