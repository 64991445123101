import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useAppFlowContext } from '../context/AppFlowProvider';
import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { UPDATE_BILLING } from './common/GraphQLMutations';
import { Alert } from '@mui/material';

export const BillingForm = (props: any) => {
  const {
    setSubmitHandlerClicked,
    submitHandlerClicked,
    setBillingFormComplete,
    createSubscription,
    handleBillingError,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const { setBillingCompleted } = useAppFlowContext();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [cardError, setCardError] = useState<boolean>(false);

  useEffect(() => {
    elements?.getElement('payment')?.on('change', ({ complete }) => {
      setBillingFormComplete(complete);
      setSubmitHandlerClicked(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements]);

  const [updateBilling] = useMutation(UPDATE_BILLING, {
    onCompleted: (data) => {
      setBillingCompleted(true);
    },
    onError: (error) => {
      handleBillingError(
        'Error encountered during registration. Please try again.'
      );
    },
  });

  const handleSubmit = useCallback(async () => {
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    if (result.error) {
      setErrorMessage(result.error.message);
      setCardError(true);
    } else {
      setErrorMessage('');
      createSubscription();
      updateBilling({ variables: { value: true } });
    }
  }, [stripe, elements, createSubscription, updateBilling]);

  useEffect(() => {
    if (submitHandlerClicked) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitHandlerClicked]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
      </form>
      {cardError && (
        <Alert
          style={{ marginBottom: '0px', marginTop: '20px' }}
          severity='error'
          onClose={() => {
            setCardError(false);
          }}>
          {errorMessage || 'Error occurred while processing card.'}
          <br />
          <a
            style={{ color: 'inherit' }}
            href='mailto:admin@integratedroadways.com'>
            Contact your admin for support.
          </a>
        </Alert>
      )}
    </>
  );
};
