import { useAuth0 } from '@auth0/auth0-react';
import { AcceptButton, DeclineButton } from './common/buttons/Button';
import { CustomIcon, CustomIconType } from './common/icons/Icons';
import { MUIModal } from './common/MUIModal';
import './RegistrationModal.css';

interface IModalProps {
  showModal: boolean;
  continueHandler: () => void;
}

export const RegistrationModal = (props: IModalProps): JSX.Element => {
  const { logout } = useAuth0();
  return (
    <MUIModal
      open={props.showModal}
      children={
        <div id='myModal' className='modal'>
          <div className='modal-content'>
            <span className='close' onClick={props.continueHandler}>
              &times;
            </span>
            <h2>WARNING!</h2>
            <p>Are you sure you want to quit? All changes will be lost.</p>
            <div className='modal-nav'>
              <DeclineButton
                onClickHandler={() =>
                  logout({ returnTo: window.location.origin })
                }
                icon={
                  <CustomIcon iconType={CustomIconType.LeftArrowIcon} rawSvg />
                }
                text='Yes, Quit'
              />
              <AcceptButton
                onClickHandler={props.continueHandler}
                icon={
                  <CustomIcon iconType={CustomIconType.RightArrowIcon} rawSvg />
                }
                text='No, Stay here'
              />
            </div>
          </div>
        </div>
      }></MUIModal>
  );
};
