import * as Yup from 'yup';

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const zipCodeRegExp = /^\d{5}$/;
const stateRegExp = /^[a-zA-Z\s]+$/;

export const profileDataValidationSchema = Yup.object().shape({
  profile: Yup.object().shape({
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
    phone_number: Yup.string()
      .required('Required')
      .matches(phoneRegExp, 'Phone number is not valid'),
    address: Yup.object().shape({
      street: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      state: Yup.string()
        .required('Required')
        .matches(stateRegExp, 'State is not valid'),
      zip_code: Yup.string()
        .required('Required')
        .matches(zipCodeRegExp, 'Zip Code is not valid'),
    }),
  }),
  email: Yup.string().email('Invalid email address'),
  company: Yup.object().shape({
    name: Yup.string(),
    email: Yup.string().email('Invalid email address'),
    phone_number: Yup.string().matches(
      phoneRegExp,
      'Phone number is not valid'
    ),
    address: Yup.object().shape({
      street: Yup.string(),
      city: Yup.string(),
      state: Yup.string().matches(stateRegExp, 'State is not valid'),
      zip_code: Yup.string().matches(zipCodeRegExp, 'Zip Code is not valid'),
    }),
  }),
});
