import './Sidebar.css';
import { NavLink } from 'react-router-dom';
import { CustomIconType, CustomIcon } from '../common/icons/Icons';
import { useAppFlowContext } from '../../context/AppFlowProvider';
import { cypressUser } from '../helpers/helperFunctions';

const Sidebar = () => {
  const { currentUser, emailVerified, errorMessage } = useAppFlowContext();

  let isAdmin: boolean =
    currentUser?.roles.some((role) => role.name === 'admin') || false;

  const showSidebar: boolean =
    (emailVerified && !cypressUser(currentUser?.email)) ||
    cypressUser(currentUser?.email);

  return (
    <nav
      className='left-nav'
      style={errorMessage ? { marginTop: '-108px' } : {}}>
      {showSidebar && (
        <ul>
          {isAdmin && (
            <li>
              <NavLink
                to='/admin/dashboard'
                isActive={(match, location) =>
                  location.pathname.startsWith('/admin/') || false
                }
                className='left-nav-routing'>
                <CustomIcon iconType={CustomIconType.SidebarIconAdmin} rawSvg />
                Admin
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              exact
              to='/dashboard'
              className='left-nav-routing admin-sidebar'>
              <CustomIcon
                iconType={CustomIconType.SidebarIconDashboard}
                rawSvg
              />
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to='/account'
              className='left-nav-routing my-account'>
              <CustomIcon iconType={CustomIconType.AccountInfoIcon} rawSvg />
              My Account
            </NavLink>
          </li>
          <li>
            <NavLink to='/my-info' className='left-nav-routing my-info'>
              <CustomIcon iconType={CustomIconType.SidebarMyInfo} rawSvg />
              My Info
            </NavLink>
          </li>
          {!isAdmin && (
            <li>
              <NavLink
                exact
                to='/billing'
                className='left-nav-routing my-billing'>
                <CustomIcon
                  iconType={CustomIconType.SidebarIconBilling}
                  rawSvg
                />
                Billing
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to='/activity'
              className='left-nav-routing activity-sidebar'>
              <CustomIcon iconType={CustomIconType.Activity} rawSvg />
              Activity
            </NavLink>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Sidebar;
