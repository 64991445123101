import { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useMutation } from '@apollo/client';
import { UPDATE_MFA } from './common/GraphQLMutations';
import { useAppFlowContext } from '../context/AppFlowProvider';
interface EnableMfaProps {
  value: boolean;
}

const EnableMfa = (props: EnableMfaProps): JSX.Element => {
  const { setErrorMessage } = useAppFlowContext();
  const [updateMfa, { loading }] = useMutation(UPDATE_MFA, {
    onError: () => {
      setErrorMessage(true);
    },
  });

  const [checked, setChecked] = useState(props.value);
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    updateMfa({ variables: { value: event.target.checked } });
  };

  return (
    <Checkbox disabled={loading} checked={checked} onChange={handleChange} />
  );
};

export default EnableMfa;
