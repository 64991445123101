export const BlueGradient = (): JSX.Element => {
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id="blueGradient"
          x1="0%" y1="0%" x2="0%" y2="100%"
        >
          <stop offset="0%" stopColor="#0099FF" />
          <stop offset="100%" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};