import Checkbox from '@mui/material/Checkbox';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { UPDATE_BLOCKED_STATUS } from './GraphQLMutations';
import { useAppFlowContext } from '../../context/AppFlowProvider';
interface BlockedStatusCellProps {
  user_id: string;
  subscription_id: string;
  value: boolean;
}

const BlockedStatusCell = (props: BlockedStatusCellProps): JSX.Element => {
  const { setErrorMessage } = useAppFlowContext();
  const [checked, setChecked] = useState(props.value);

  const [updateBlockedStatus, { loading }] = useMutation(
    UPDATE_BLOCKED_STATUS,
    {
      onError: () => {
        setErrorMessage(true);
      },
    }
  );

  const handleChange = (event: any) => {
    event.stopPropagation();
    setChecked(event.target.checked);
    updateBlockedStatus({
      variables: {
        user_id: props.user_id,
        subscription_id: props.subscription_id || '',
        value: event.target.checked || false,
      },
    });
  };
  return (
    <Checkbox disabled={loading} checked={checked} onChange={handleChange} />
  );
};

export default BlockedStatusCell;
