import { Field, useField, useFormikContext } from 'formik';
import { StatesList } from '../../assets/states-list';

const stateOptions = () => {
  const options: any[] = [<option value={''}></option>];
  for (const [key, value] of Object.entries(StatesList)) {
    options.push(<option value={key}>{value}</option>);
  }
  return options;
};

const StateSelectInput = ({ field, className }: any) => {
  const { setFieldValue } = useFormikContext();

  return (
    <select
      {...field}
      className={className}
      onChange={(input: any) => {
        setFieldValue(field.name, input.target.value);
      }}>
      {stateOptions()}
    </select>
  );
};

interface InputProps {
  name: string;
  required?: boolean;
}

export const StateSelect = (props: InputProps) => {
  const { 1: meta } = useField('state');

  return (
    <>
      <label htmlFor={props.name}>
        State
        {props.required ? '*' : null}
      </label>
      <Field
        name={props.name}
        component={StateSelectInput}
        className={
          meta.touched && meta.error ? 'text-input input-error' : 'text-input'
        }
      />
      {meta.touched && meta.error ? (
        <div className='error'>
          {meta.error === 'Required' ? `State ${meta.error}` : meta.error}
        </div>
      ) : (
        <div className='error-hidden'>hidden</div>
      )}
    </>
  );
};
