import dayjs from 'dayjs';
import { months } from './helpers/constants';
import { useQuery } from '@apollo/client';
import { GET_INCOME } from './common/GraphQLQueries';
import { IIncomeData } from './Types';
import { useEffect, useState } from 'react';
import { CurrentMonthIncomeChart } from './CurrentMonthIncomeChart';
import { CurrentYearIncomeChart } from './CurrentYearIncomeChart';
import { LinearProgress } from '@mui/material';
import { Breadcrumbs } from './common/Breadcrumbs';
import { useAppFlowContext } from '../context/AppFlowProvider';
import { useHistory } from 'react-router-dom';

const getThisMonthsIncome = (data: IIncomeData[]) =>
  data.reduce((previous: number, current: IIncomeData) => {
    if (dayjs(current.start).month() === dayjs().month()) {
      return previous + Number(current.total);
    }
    return previous;
  }, 0);

export const MonthToDateIncome = () => {
  const { setErrorMessage } = useAppFlowContext();
  const [currentMonthIncome, setCurrentMonthIncome] = useState(0);
  const history = useHistory();

  const monthToDateTitle = `${
    months[dayjs().month()]
  } ${dayjs().year()} (month to date)`;

  const { loading: incomeLoading, data: incomeData } = useQuery(GET_INCOME, {
    variables: {
      start: dayjs()
        .startOf('month')
        .subtract(6, 'months')
        .format('YYYY-MM-DD'),
      end: dayjs().endOf('month').format('YYYY-MM-DD'),
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  useEffect(() => {
    if (incomeData) {
      setCurrentMonthIncome(getThisMonthsIncome(incomeData.incomeForPeriod));
    }
  }, [incomeData]);

  return (
    <>
      <Breadcrumbs
        primary='Admin'
        current='Monthly Income'
        primaryClickHandler={() => history.push('/admin/dashboard')}
      />
      <div className='page-title'>Monthly Income</div>
      <section className='graph-section'>
        <div className='left-graph'>
          <div className='graph-top'>{monthToDateTitle}</div>
          {incomeLoading && <LinearProgress />}
          <div className='left-graph-chart daily-chart'>
            <CurrentMonthIncomeChart income={currentMonthIncome} />
          </div>
        </div>

        <div className='right-graph' style={{ width: '100%' }}>
          <div className='graph-top'>Monthly Income</div>
          {incomeLoading && <LinearProgress />}
          <div className='right-graph-chart monthly-chart'>
            <CurrentYearIncomeChart
              income={incomeData?.incomeForPeriod || []}
            />
          </div>
        </div>
      </section>
    </>
  );
};
