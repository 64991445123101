import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { getConfig } from './config';
import AuthorizedApolloProvider from './components/AuthorizedApolloProvider';
import reportWebVitals from './reportWebVitals';
import { AppFlowProvider } from './context/AppFlowProvider';

const config = getConfig();
const onRedirectCallback = (appState: any) => {};

// See https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const auth0ProviderConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.api.audience ? { audience: config.api.audience } : null),
  redirectUri: window.location.origin,
  useRefreshTokens: true,
  scope: "openid profile email offline_access",
  onRedirectCallback,
};

ReactDOM.render(
  <React.StrictMode>
    <AppFlowProvider>
      <Auth0Provider {...auth0ProviderConfig}>
        <AuthorizedApolloProvider>
          <App />
        </AuthorizedApolloProvider>
      </Auth0Provider>
    </AppFlowProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals(console.log);
