import { useAppFlowContext } from '../context/AppFlowProvider';
import { AlertBanner } from './common/AlertBanner';

const RegisterEmail = () => {
  const { currentUser } = useAppFlowContext();

  const text: string = `Please verify your email address before continuing. A verification
  email has been sent to ${currentUser?.email}. After confirming your email, you can refresh this page to continue.`;

  return (
    <>
      <AlertBanner
        text={text}
        severity='error'
        style={{
          width: '80%',
          margin: 'auto',
        }}
      />
    </>
  );
};

export default RegisterEmail;
