import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_PORTAL_SESSION } from './common/GraphQLMutations';
import { useAppFlowContext } from '../context/AppFlowProvider';
import { AlertBanner } from './common/AlertBanner';
import './StripeCustomerPortal.css';

const StripeCustomerPortal = () => {
  const {
    stripeCustomerId,
    customerPortal,
    setCustomerPortal,
    paymentFailed,
    setErrorMessage,
  } = useAppFlowContext();

  const [createPortalSession] = useMutation(CREATE_PORTAL_SESSION, {
    onCompleted: (result) => {
      setCustomerPortal(result.createStripePortalSession?.url);
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  useEffect(() => {
    if (stripeCustomerId) {
      createPortalSession({
        variables: {
          customer_id: stripeCustomerId,
          return_url: window.location.href,
        },
      });
    }
  }, [stripeCustomerId, createPortalSession]);

  return (
    <>
      {paymentFailed && (
        <AlertBanner
          text='There is an issue with your subscription - To correct it, please '
          linkText='Continue to Stripe'
          severity='error'
          href={customerPortal}
          style={{
            marginTop: '40px',
            marginRight: '40px',
          }}
        />
      )}
      <div className='page-title'>Billing</div>

      <div className='stripe'>
        <p>
          Integrated Roadways uses Stripe to manage your account billing. To
          view and manage your billing information please click the button
          below.
        </p>
        <p>
          When you are finished click the “Back to Integrated Roadways Portal”
          link on the left in the Stripe window.
        </p>
        <button
          className='big-blue-btn'
          onClick={() => window.location.assign(customerPortal)}>
          Continue to Stripe
        </button>
      </div>
    </>
  );
};

export default StripeCustomerPortal;
