import { Alert } from '@mui/material';

interface IAlertBannerProps {
  text: string;
  href?: string;
  linkText?: string;
  severity: 'error' | 'warning' | 'info' | 'success';
  style: Object;
  onClose?: () => void;
}

export const AlertBanner = (props: IAlertBannerProps) => {
  return (
    <Alert
      style={props.style}
      severity={props.severity}
      onClose={props.onClose}>
      {props.text}
      {props.href ? (
        <a style={{ color: 'inherit' }} href={props.href}>
          {props.linkText ? props.linkText : 'Click Here To Update'}
        </a>
      ) : null}
    </Alert>
  );
};
