export const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'Jul',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];
