import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { GET_EXPENSES } from './common/GraphQLQueries';
import { useQuery } from '@apollo/client';
import { BasicWidget } from './common/widgets/BasicWidget';
import { useAppFlowContext } from '../context/AppFlowProvider';

export const MTDExpensePanel = (): JSX.Element => {
  const [expenses, setExpenses] = useState('--');
  const { setErrorMessage } = useAppFlowContext();
  const firstDayOfMonth = dayjs().startOf('month');
  const lastDayOfMonth = dayjs().endOf('month');
  const now = dayjs();

  const { loading: expensesLoading, data: expenseData } = useQuery(
    GET_EXPENSES,
    {
      variables: {
        start: firstDayOfMonth.format('YYYY-MM-DD'),
        end: lastDayOfMonth.format('YYYY-MM-DD'),
      },
      onError: () => {
        setErrorMessage(true);
      },
    }
  );

  useEffect(() => {
    if (expenseData) {
      setExpenses(
        '$' + parseFloat(expenseData.expensesForPeriod[0].total).toFixed(2)
      );
    }
  }, [expenseData]);

  return (
    <BasicWidget
      title={
        'Current Month Expenses (' +
        firstDayOfMonth.format('MMM D') +
        '-' +
        now.format('MMM D') +
        ')'
      }
      data={expenses}
      dataFormatting='String'
      footerText='View More'
      footerLink='/admin/dashboard/expenses'
      loading={expensesLoading}
    />
  );
};
