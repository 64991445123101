import './BottomNav.css';
import { AcceptButton, Button } from './buttons/Button';
import { CustomIcon, CustomIconType } from './icons/Icons';

export interface IBottomNavProps {
  submitOnClickHandler?: () => void;
  cancelOnClickHandler?: () => void;
  disabled?: boolean;
  hasBackButton: boolean;
  backOnClickHandler?: () => void;
  submitText: string;
  cancelText: string;
}

export const BottomNav = (props: IBottomNavProps): JSX.Element => {
  return (
    <div className='bottom-nav'>
      {props.hasBackButton && (
        <Button
          text='Back'
          onClickHandler={props.backOnClickHandler}
          type='button'
          className='txtBtn'
        />
      )}

      <Button
        text={props.cancelText}
        onClickHandler={props.cancelOnClickHandler}
        type='button'
        className='txtBtn'
      />

      <AcceptButton
        type='submit'
        onClickHandler={props.submitOnClickHandler}
        disabled={props.disabled}
        icon={<CustomIcon iconType={CustomIconType.SaveIcon} rawSvg />}
        text={props.submitText}
      />
    </div>
  );
};
