import { Field, useField, useFormikContext } from 'formik';
import './CreateProfileInput.css';
import NumberFormat from 'react-number-format';

interface inputProps {
  label: string;
  name: string;
  type: string;
  format?: string;
  required?: boolean;
}

const CreateProfileInput = ({
  label,
  name,
  type,
  format,
  required,
}: inputProps) => {
  const { 1: meta } = useField(name);
  return (
    <>
      <label htmlFor={name}>
        {label}
        {required ? '*' : null}
      </label>
      <Field
        name={name}
        component={ProfileInput}
        type={type}
        className={
          meta.touched && meta.error ? 'text-input input-error' : 'text-input'
        }
        format={format}
      />

      {meta.touched && meta.error ? (
        <div className='error'>
          {meta.error === 'Required' ? `${label} ${meta.error}` : meta.error}
        </div>
      ) : (
        <div className='error-hidden'>hidden</div>
      )}
    </>
  );
};

function ProfileInput({ field, format, className, type }: any) {
  const { setFieldValue } = useFormikContext();

  return format ? (
    <NumberFormat
      format={format}
      className={className}
      {...field}
      onChange={(input: any) => {
        setFieldValue(field.name, input.target.value);
      }}
    />
  ) : (
    <input
      className={className}
      {...field}
      type={type}
      onChange={(input: any) => {
        setFieldValue(field.name, input.target.value);
      }}
    />
  );
}

export default CreateProfileInput;
