import './Header.css';
import logo from '../../assets/ir-logo-header.png';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './LoginButton';
import { CustomIcon, CustomIconType } from './icons/Icons';
import LogoutButton from './LogoutButton';
import { useAppFlowContext } from '../../context/AppFlowProvider';

const Header = ({ showNavIcons }: { showNavIcons: Boolean }) => {
  const { isAuthenticated } = useAuth0();
  const { currentUser, isRegistered, emailVerified } = useAppFlowContext();

  return (
    <div>
      <header>
        <nav className='navbar'>
          <div className='brand-title'>
            <Link to={isRegistered && emailVerified ? '/' : '#'}>
              <img src={logo} alt='Integrated Roadways Logo' />
            </Link>
          </div>
          {showNavIcons && (
            <div className='navbar-links'>
              <ul>
                {/* home */}
                <li>
                  <Link to='/' className='navbar-links-routing'>
                    <CustomIcon
                      iconType={CustomIconType.HeaderHomeIcon}
                      rawSvg
                    />
                  </Link>
                </li>
                {!isAuthenticated && <LoginButton />}
                {/* User Icon */}
                {isAuthenticated && (
                  <li>
                    <Link to='/account' className='navbar-links-routing'>
                      <CustomIcon
                        iconType={CustomIconType.HeaderUserIcon}
                        rawSvg
                      />
                      <div className='user-name'>
                        {currentUser?.profile.last_name}
                      </div>
                    </Link>
                  </li>
                )}
                {isAuthenticated && (
                  <li>
                    <LogoutButton />
                  </li>
                )}
              </ul>
            </div>
          )}
        </nav>
      </header>
    </div>
  );
};

export default Header;
