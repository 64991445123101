import configJson from './config.json';

export function getConfig() {
  return {
    domain: configJson.domain,
    clientId: configJson.clientId,
    googleAnalytics: configJson.googleAnalytics,
    api: {
      origin: configJson.api.origin,
      audience: configJson.api.audience
        ? configJson.api.audience
        : configJson.api.origin,
      stripeKey: configJson.api.stripeKey,
    },
  };
}
