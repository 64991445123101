import dayjs from 'dayjs';
import { IIncomeData } from './Types';
import { VictoryChart, VictoryAxis, VictoryVoronoiContainer, VictoryTooltip, VictoryArea, VictoryGroup } from 'victory';
import { months } from './helpers/constants';
import { BlueGradient } from './common/buttons/ChartGradients';

export const shapeTableData = (data: IIncomeData[]) => {
  let accData: any = {};
  let valueCount = 0;

  data.forEach((item: IIncomeData) => {
    const month = dayjs(item.start).month();
    if (dayjs(item.start).isAfter(dayjs().subtract(12, 'month'))) {
      if (accData[month]) {
        accData[month] = {
          ...accData[month],
          total: Number(item.total),
        };
      } else {
        accData[month] = {
          value: valueCount,
          month: month,
          total: Number(item.total),
        };
        valueCount++;
      }
    }
  });
  return Object.values(accData).map(
    (item: any) => {
      return item;
    }
  );
};

interface ICurrentYearIncomeChart {
  income: IIncomeData[];
}

export const CurrentYearIncomeChart = (props: ICurrentYearIncomeChart) => {
  const reshapedTableData = shapeTableData(props.income);

  return (
    <>
      <VictoryChart width={1200} domainPadding={50}
        containerComponent={
          <VictoryVoronoiContainer />
        }>
        <VictoryAxis
          tickValues={reshapedTableData.map(
            (item: any) => item.value
          )}
          tickFormat={reshapedTableData.map(
            (item: any) => months[item.month]
          )}
        />
        <VictoryAxis
          tickFormat={(t) => `$${t}`}
          style={{
            grid: {
              fill: '#1BA4FF',
              stroke: '#818e99',
              strokeWidth: 0.5,
            },
          }}
          dependentAxis
        />
        <VictoryGroup
          style={{
            data: { strokeWidth: 3 }
          }}
        >
          <VictoryArea
            data={reshapedTableData}
            labels={({ datum }) => `$${parseFloat(datum.total).toFixed(2)}`}
            labelComponent={<VictoryTooltip
              pointerOrientation="right"
              dy={0}
              dx={0}
              pointerWidth={25}
              cornerRadius={0}
              centerOffset={{ x: -40 }}
            />}
            x='value'
            y='total'
            style={{
              data: {
                stroke: '#646569',
                fill: 'url(#blueGradient)',
                strokeWidth: 3.0
              }
            }}
          />
        </VictoryGroup>

      </VictoryChart>
      <BlueGradient />
    </>
  );
};
