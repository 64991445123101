import { IDailyStatsData } from './Types';
import dayjs from 'dayjs';
import { VictoryChart, VictoryAxis, VictoryBar, VictoryTooltip } from 'victory';

const today = new Date();
let sevenDaysAgo = new Date(dayjs(today).subtract(7, 'day').toDate());

export const filterDays = (data: IDailyStatsData[]) =>
  data
    .map((item: any) => {
      const theDate = new Date(item.date);
      return dayjs(theDate).isAfter(sevenDaysAgo)
        ? { day: theDate.getDay() + 1, logins: item.logins }
        : undefined;
    })
    .filter((i: any) => !!i);

export const DailyActiveUsersChart = (props: any) => (
  <VictoryChart domainPadding={{ x: 30 }}>
    <VictoryAxis
      tickValues={[1, 2, 3, 4, 5, 6, 7]}
      tickFormat={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
    />
    <VictoryAxis
      style={{
        grid: { stroke: '#818e99', strokeWidth: 0.5 },
      }}
      dependentAxis
    />
    <VictoryBar
      data={props.dailyData}
      labels={({ datum }) => `${datum.logins}`}
      labelComponent={
        <VictoryTooltip
          pointerOrientation='right'
          dy={0}
          dx={0}
          pointerWidth={25}
          cornerRadius={0}
          centerOffset={{ x: -20 }}
        />
      }
      x='day'
      y='logins'
      alignment='middle'
      barRatio={0.8}
      style={{
        data: {
          fill: '#FFAE0D',
          stroke: '#FFAE0D',
        },
      }}
    />
  </VictoryChart>
);
