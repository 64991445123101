import { MTDIncomePanel } from './MTDIncomePanel';
import { MTDExpensePanel } from './MTDExpensePanel';
import { UserCountPanel } from './UserCountPanel';
import { LoginStatsPanel } from './LoginStatsPanel';
import DashboardConfiguration from './DashboardConfiguration';

const AdminDashboard = () => {

  return (
    <>
      <section className='card-section admin-dashboard'>
        <UserCountPanel />

        <MTDIncomePanel />

        <MTDExpensePanel />
      </section>

      <section className='graph-section'>
        <LoginStatsPanel />
      </section>

      <DashboardConfiguration />
    </>
  );
};

export default AdminDashboard;
