import dayjs from 'dayjs';
import { CurrentYearExpensesChart } from './CurrentYearExpensesChart';
import { CurrentMonthExpensesChart } from './CurrentMonthExpensesChart';
import { months } from './helpers/constants';
import { useQuery } from '@apollo/client';
import { IExpenseData } from './Types';
import { useEffect, useState } from 'react';
import { GET_EXPENSES } from './common/GraphQLQueries';
import { LinearProgress } from '@mui/material';
import { Breadcrumbs } from './common/Breadcrumbs';
import { useAppFlowContext } from '../context/AppFlowProvider';
import { useHistory } from 'react-router-dom';

const getThisMonthsExpenses = (data: IExpenseData[]) =>
  data.reduce((previous: number, current: IExpenseData) => {
    if (dayjs(current.start).month() === dayjs().month()) {
      return previous + Number(current.total);
    }
    return previous;
  }, 0);

export const MonthToDateExpenses = () => {
  const { setErrorMessage } = useAppFlowContext();
  const [currentMonthExpenses, setCurrentMonthExpenses] = useState(0);
  const history = useHistory();

  const monthToDateTitle = `${
    months[dayjs().month()]
  } ${dayjs().year()} (month to date)`;

  const { loading: expensesLoading, data: expenseData } = useQuery(
    GET_EXPENSES,
    {
      variables: {
        start: dayjs()
          .startOf('month')
          .subtract(6, 'months')
          .format('YYYY-MM-DD'),
        end: dayjs().endOf('month').format('YYYY-MM-DD'),
      },
      onError: () => {
        setErrorMessage(true);
      },
    }
  );

  useEffect(() => {
    if (expenseData) {
      setCurrentMonthExpenses(
        getThisMonthsExpenses(expenseData.expensesForPeriod)
      );
    }
  }, [expenseData]);

  return (
    <>
      <Breadcrumbs
        primary='Admin'
        current='Monthly Expenses'
        primaryClickHandler={() => history.push('/admin/dashboard')}
      />
      <div className='page-title'>Monthly Expenses</div>
      <section className='graph-section'>
        <div className='left-graph'>
          <div className='graph-top'>{monthToDateTitle}</div>
          {expensesLoading && <LinearProgress />}
          <div className='left-graph-chart daily-chart'>
            <CurrentMonthExpensesChart expenses={currentMonthExpenses} />
          </div>
        </div>

        <div className='right-graph'>
          <div className='graph-top'>Monthly Expenses</div>
          {expensesLoading && <LinearProgress />}
          <div className='right-graph-chart monthly-chart'>
            <CurrentYearExpensesChart
              expenses={expenseData?.expensesForPeriod || []}
            />
          </div>
        </div>
      </section>
    </>
  );
};
