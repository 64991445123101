import React from 'react';
import numberCircleOne from '../assets/number-circle-1.png';
import numberCircleTwo from '../assets/number-circle-2-gray.png';
import './RegistrationHeader.css';
import { CustomRegistrationHeaderType } from './Types';

interface IRegistrationHeaderProps {
  page: CustomRegistrationHeaderType;
}

const RegistrationHeader = (props: IRegistrationHeaderProps) => {
  let headerOneClass;
  let headerTwoClass;

  if (props.page === CustomRegistrationHeaderType.Profile) {
    headerOneClass = 'header-1';
    headerTwoClass = 'header-2 header-gray';
  }
  if (props.page === CustomRegistrationHeaderType.Billing) {
    headerOneClass = 'header-1 header-green';
    headerTwoClass = 'header-2 header-yellow';
  }
  return (
    <div className='registration-header'>
      <div className={headerOneClass}>
        <div className='cnt'>
          <img src={numberCircleOne} alt='Circle One' />
          <p>Please add your personal and company information</p>
        </div>
      </div>
      <div className={headerTwoClass}>
        <div className='cnt'>
          <img src={numberCircleTwo} alt='Circle two' />
          <p>Please add your billing address and method</p>
        </div>
      </div>
    </div>
  );
};

export default RegistrationHeader;
