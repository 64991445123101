import './SubscriptionCard.css';
import { Button } from './common/buttons/Button';
import { useState } from 'react';
import { SubscriptionDetailsModal } from './SubscriptionDetailsModal';
import { formatPrice } from './helpers/helperFunctions';

interface ISubscriptionCardProps {
  sub: any;
  focus: boolean;
  switchCurrentSub: (sub: any) => void;
}

export const SubscriptionCard = (
  props: ISubscriptionCardProps
): JSX.Element => {
  const [showSubModal, setShowSubModal] = useState<boolean>(false);
  const flipSubModal = () => setShowSubModal((current) => !current);

  return (
    <div
      className={props.focus ? 'subscription-card-focus' : 'subscription-card'}>
      <div className='subscription-card-top'>
        <div className='subscription-card-info'>
          <p>{props.sub?.name}</p>
          <h5>{formatPrice(props?.sub?.price)}</h5>
        </div>
      </div>
      <div className='subscription-card-footer'>
        {!props.focus && (
          <Button
            className='select-button'
            text='Select'
            onClickHandler={() => props.switchCurrentSub(props.sub)}></Button>
        )}
        <Button text='View Details' onClickHandler={flipSubModal}></Button>
      </div>
      <SubscriptionDetailsModal
        key={props.sub?.name + 'modal'}
        showModal={showSubModal}
        continueHandler={() => {
          props.switchCurrentSub(props.sub);
          flipSubModal();
        }}
        cancelHandler={flipSubModal}
        name={props.sub?.name}
        description={props.sub?.description}
        focus={props.focus}></SubscriptionDetailsModal>
    </div>
  );
};
