import { useMutation } from '@apollo/client';
import { Button } from '../components/common/buttons/Button';
import { CustomIcon, CustomIconType } from '../components/common/icons/Icons';
import { SEND_PASSWORD_EMAIL } from './common/GraphQLMutations';
import { useAppFlowContext } from '../context/AppFlowProvider';
interface IChangePasswordProps {
  onClickHandler: () => void;
}

const ChangePassword = (props: IChangePasswordProps) => {
  const { setErrorMessage } = useAppFlowContext();
  const [sendPasswordEmail] = useMutation(SEND_PASSWORD_EMAIL, {
    onError: () => {
      setErrorMessage(true);
    },
  });
  return (
    <div className='user-details-card'>
      <Button
        className='big-blue-btn'
        icon={
          <CustomIcon iconType={CustomIconType.ChangePasswordIcon} rawSvg />
        }
        text='Change Password'
        disabled={false}
        type='button'
        onClickHandler={() => {
          sendPasswordEmail();
          props.onClickHandler();
        }}
      />
    </div>
  );
};

export default ChangePassword;
