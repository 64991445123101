import RegistrationHeader from './RegistrationHeader';
import { CustomRegistrationHeaderType, SubscriptionTier } from './Types';
import { useMutation, useQuery } from '@apollo/client';
import {
  CREATE_STRIPE_CUSTOMER,
  CREATE_STRIPE_SETUP_INTENT,
  CREATE_STRIPE_SUBSCRIPTION,
} from './common/GraphQLMutations';
import { GET_SUBSCRIPTION } from './common/GraphQLQueries';
import { useCallback, useEffect, useState } from 'react';
import Loading from './common/Loading';
import { RegistrationModal } from './RegistrationModal';
import { useAppFlowContext } from '../context/AppFlowProvider';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BillingForm } from './BillingForm';
import { getConfig } from '../config';
import { ModifyBillingBottomNav } from './ModifyBillingBottomNav';
import { SubscriptionSelectForm } from './SubscriptionSelectForm';
import { useHistory } from 'react-router-dom';
import './ModifyBilling.css';
import { Alert } from '@mui/material';

const config = getConfig();

const stripePromise = loadStripe(config.api.stripeKey);

export const ModifyBilling = (): JSX.Element => {
  const {
    currentUser,
    setProfileCompleted,
    subscriptionData,
    setSubscriptionData,
    squeezePlan,
    setupIntentId,
    setSetupIntentId,
    stripeCustomerId,
    setStripeCustomerId,
    billingCompleted,
  } = useAppFlowContext();

  const [subs, setSubs] = useState<Array<object>>([]);
  const [selectedPlan, setSelectedPlan] = useState<SubscriptionTier>();
  const switchSelectedPlan = (plan: any) => {
    setSelectedPlan(plan);
  };

  const [showModal, setShowModal] = useState<boolean>(false);
  const flipModal = () => setShowModal((current) => !current);

  const [submitHandlerClicked, setSubmitHandlerClicked] =
    useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [billingFormComplete, setBillingFormComplete] =
    useState<boolean>(false);
  const [billingError, setBillingError] = useState<boolean>(false);
  const [billingErrorMessage, setBillingErrorMessage] = useState<string>(
    'Error encountered during registration. Please try again.'
  );
  const handleBillingError = (message: string) => {
    setBillingErrorMessage(message);
    setBillingError(true);
    setDataLoading(false);
  };

  const { data: subscriptions } = useQuery(GET_SUBSCRIPTION, {
    onError: (error) => {
      setBillingErrorMessage(error.message);
      setBillingError(true);
      setDataLoading(false);
    },
  });

  useEffect(() => {
    if (subscriptions) {
      subscriptions.subscriptionTiers.forEach((sub: any) => {
        if (sub.name.toUpperCase() === squeezePlan?.toUpperCase()) {
          setSelectedPlan(sub);
        }
        setSubs((s) => [...(s ?? []), sub]);
      });
    }
  }, [squeezePlan, subscriptions]);

  const history = useHistory();
  useEffect(() => {
    if (billingCompleted) {
      history.push('/');
    }
  }, [billingCompleted, history]);

  const [createStripeCustomer] = useMutation(CREATE_STRIPE_CUSTOMER, {
    onCompleted: (data) => {
      setStripeCustomerId(data.createStripeCustomer.id);
    },
    onError: (error) => {
      setBillingErrorMessage(error.message);
      setBillingError(true);
      setDataLoading(false);
    },
  });

  const [createStripeSetupIntent] = useMutation(CREATE_STRIPE_SETUP_INTENT, {
    onCompleted: (data) => {
      setSubscriptionData({
        clientSecret: data.createStripeSetupIntent.client_secret,
      });
      setSetupIntentId(data.createStripeSetupIntent.id);
      setDataLoading(false);
    },
    onError: (error) => {
      setBillingErrorMessage(error.message);
      setBillingError(true);
      setDataLoading(false);
    },
  });

  const [createStripeSubscription] = useMutation(CREATE_STRIPE_SUBSCRIPTION, {
    onError: (error) => {
      setBillingErrorMessage(error.message);
      setBillingError(true);
      setDataLoading(false);
    },
  });

  const handleCreateSubscription = useCallback(async () => {
    createStripeSubscription({
      variables: {
        customer: stripeCustomerId,
        price_id: selectedPlan?.id || '',
        setup_id: setupIntentId,
      },
    });
  }, [createStripeSubscription, stripeCustomerId, selectedPlan, setupIntentId]);

  useEffect(() => {
    if (stripeCustomerId) {
      if (!setupIntentId) {
        createStripeSetupIntent({
          variables: {
            customer: stripeCustomerId,
          },
        });
      } else {
        setDataLoading(false);
      }
    } else {
      if (currentUser?.email) {
        createStripeCustomer({
          variables: { email: currentUser?.email },
        });
      }
    }
  }, [
    createStripeCustomer,
    createStripeSetupIntent,
    stripeCustomerId,
    setupIntentId,
    currentUser,
  ]);

  if (dataLoading) return <Loading></Loading>;

  const options = {
    clientSecret: subscriptionData.clientSecret,
    appearance: {
      variables: {
        fontFamily: 'Arial, Helvetica, sans-serif',
        borderRadius: '0',
        colorText: '#000000',
      },
    },
  };

  return (
    <div className='center billing-container'>
      <RegistrationHeader page={CustomRegistrationHeaderType.Billing} />
      {billingError && (
        <Alert
          style={{ marginBottom: '0px', marginTop: '5px' }}
          severity='error'
          onClose={() => {
            setBillingError(false);
          }}>
          {billingErrorMessage}
          <br />
          <a
            style={{ color: 'inherit' }}
            href='mailto:admin@integratedroadways.com'>
            If the problem persists, contact support at
            admin@integratedroadways.com
          </a>
        </Alert>
      )}
      <div className='user-details'>
        <div className='user-details-card billing-details-card'>
          {subscriptionData.clientSecret && (
            <Elements stripe={stripePromise} options={options}>
              <BillingForm
                submitHandlerClicked={submitHandlerClicked}
                setSubmitHandlerClicked={setSubmitHandlerClicked}
                setBillingFormComplete={setBillingFormComplete}
                createSubscription={handleCreateSubscription}
                handleBillingError={handleBillingError}
              />
            </Elements>
          )}
        </div>
        <SubscriptionSelectForm
          subscriptions={subs}
          currentSubscription={selectedPlan}
          switchCurrentSub={switchSelectedPlan}
        />
      </div>
      <ModifyBillingBottomNav
        backOnClickHandler={() => {
          setProfileCompleted(false);
        }}
        cancelOnClickHandler={flipModal}
        submitOnClickHandler={() => setSubmitHandlerClicked(true)}
        disabled={!billingFormComplete || submitHandlerClicked}
      />
      <RegistrationModal showModal={showModal} continueHandler={flipModal} />
    </div>
  );
};
