import { BottomNav } from './common/BottomNav';

interface IProfileBottomNav {
  cancelOnClickHandler: () => void;
  disableSubmit: boolean;
  submitText: string;
  cancelText: string;
}

export const ModifyProfileBottomNav = (
  props: IProfileBottomNav
): JSX.Element => {
  return (
    <BottomNav
      disabled={props.disableSubmit}
      cancelOnClickHandler={props.cancelOnClickHandler}
      submitText={props.submitText}
      cancelText={props.cancelText}
      hasBackButton={false}
    />
  );
};
