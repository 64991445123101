// import './Header.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { MUIModal } from './MUIModal';
import { AcceptButton } from './buttons/Button';
import './Error.css';

const Error = (): JSX.Element => {
  const [open, setOpen] = useState(true);
  const { logout } = useAuth0();

  const handleClose = () => {
    setOpen(false);
    logout({ returnTo: window.location.origin });
  };
  const queryParams = new URLSearchParams(window.location.search);
  const errorDescription = queryParams.get('error_description');

  return (
    <MUIModal
      open={open}
      onClose={handleClose}
      children={
        <Box className='box-style'>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            className='modal-title'>
            Error
          </Typography>
          <Typography
            id='modal-modal-description'
            className='modal-description'>
            {errorDescription}
            <div className='modal-nav'>
              <AcceptButton onClickHandler={handleClose} text='Try Again' />
            </div>
          </Typography>
        </Box>
      }
    />
  );
};

export default Error;
