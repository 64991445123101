import { StateSelect } from './common/StateSelect';
import CreateProfileInput from './CreateProfileInput';

const CompanyInformationForm = () => {
  return (
    <div className='user-details-card'>
      <p className='user-details-title'>Company Info</p>
      <CreateProfileInput
        label='Company Name'
        name='company.name'
        type='text'
      />
      <CreateProfileInput
        label='Address'
        name='company.address.street'
        type='text'
      />
      <CreateProfileInput
        label='City'
        name='company.address.city'
        type='text'
      />
      <StateSelect name='company.address.state' />
      <CreateProfileInput
        label='Zip Code'
        name='company.address.zip_code'
        type='text'
        format='#####'
      />
      <CreateProfileInput
        label='Phone Number'
        name='company.phone_number'
        type='text'
        format='(###) ###-####'
      />
      <CreateProfileInput label='Email' name='company.email' type='text' />
    </div>
  );
};

export default CompanyInformationForm;
