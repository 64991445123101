import { MUIModal } from './common/MUIModal';
import './SubscriptionDetailsModal.css';
import { AcceptButton, Button } from './common/buttons/Button';

interface IModalProps {
  showModal: boolean;
  continueHandler: () => void;
  cancelHandler: () => void;
  name: string;
  description: string;
  focus: boolean;
}

export const SubscriptionDetailsModal = (props: IModalProps): JSX.Element => {
  return (
    <MUIModal
      open={props.showModal}
      children={
        <div className='modal' key={'subModal-' + props.name}>
          <div className='modal-content sub-modal'>
            <h1>{`Integrated Roadways ${props.name} Plan`}</h1>
            <p>{props.description}</p>
            <div className='modal-nav sub-modal-nav'>
              {!props.focus && (
                <AcceptButton
                  onClickHandler={props.continueHandler}
                  text='Select this Plan'></AcceptButton>
              )}
              <Button
                onClickHandler={props.cancelHandler}
                text='Cancel'
                type='button'
                className='txtBtn sub-modal-cancel'
              />
            </div>
          </div>
        </div>
      }></MUIModal>
  );
};
