import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { GET_INCOME } from './common/GraphQLQueries';
import { useQuery } from '@apollo/client';
import { BasicWidget } from './common/widgets/BasicWidget';
import { useAppFlowContext } from '../context/AppFlowProvider';

export const MTDIncomePanel = (): JSX.Element => {
  const { setErrorMessage } = useAppFlowContext();
  const [currentMonthIncome, setCurrentMonthIncome] = useState('--');

  const firstDayOfMonth = dayjs().startOf('month');
  const lastDayOfMonth = dayjs().endOf('month');
  const now = dayjs();
  const { loading: incomeLoading, data: incomeData } = useQuery(GET_INCOME, {
    variables: {
      start: firstDayOfMonth.format('YYYY-MM-DD'),
      end: lastDayOfMonth.format('YYYY-MM-DD'),
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  useEffect(() => {
    if (incomeData) {
      setCurrentMonthIncome(
        '$' + parseFloat(incomeData.incomeForPeriod[0].total).toFixed(2)
      );
    }
  }, [incomeData]);

  return (
    <BasicWidget
      title={
        'Current Month Income (' +
        firstDayOfMonth.format('MMM D') +
        '-' +
        now.format('MMM D') +
        ')'
      }
      data={currentMonthIncome}
      dataFormatting='String'
      footerText='View More'
      footerLink='/admin/dashboard/income'
      loading={incomeLoading}
    />
  );
};
