import { VictoryChart, VictoryAxis, VictoryBar, VictoryVoronoiContainer, VictoryTooltip, VictoryLabel } from 'victory';
import { BlueGradient } from './common/buttons/ChartGradients';


export const CurrentMonthIncomeChart = (props: { income: number }) => {
  return (
    <>
      <VictoryChart
        width={300}
        domainPadding={50}
        containerComponent={<VictoryVoronoiContainer />}>
        <VictoryAxis
          tickLabelComponent={(
            <VictoryLabel
              textAnchor="middle"
              x={150} />
          )}
          tickValues={[0]}
          tickFormat={['MTD']}
          style={{
            tickLabels: {}
          }} />
        <VictoryAxis
          tickFormat={(t) => `$${t.toFixed(0)}`}
          style={{
            grid: { stroke: '#818e99', strokeWidth: 0.5 },
          }}
          dependentAxis />
        <VictoryBar
          data={[
            {
              value: 0,
              total: props.income,
            },
          ]}
          labels={({ datum }) => `$${parseFloat(datum.total).toFixed(2)}`}
          labelComponent={<VictoryTooltip
            pointerOrientation="right"
            dy={0}
            dx={100}
            pointerWidth={25}
            cornerRadius={0}
            centerOffset={{ x: -40 }}
          />}
          style={{
            data: {
              strokeWidth: 1,
              stroke: '#646569',
              fill: 'url(#blueGradient)',
            },
          }}
          x='value'
          y='total'
          barRatio={25}
          alignment='start' />
      </VictoryChart>
      <BlueGradient />
    </>
  );
};
