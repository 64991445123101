import { StateSelect } from './common/StateSelect';
import CreateProfileInput from './CreateProfileInput';

const PersonalInformationForm = (user?: any) => {
  return (
    <div className='user-details-card'>
      <p className='user-details-title'>Personal Info</p>
      <CreateProfileInput
        label='First Name'
        name='profile.first_name'
        type='text'
        required
      />
      <CreateProfileInput
        label='Last Name'
        name='profile.last_name'
        type='text'
        required
      />
      <CreateProfileInput
        label='Address'
        name='profile.address.street'
        type='text'
        required
      />
      <CreateProfileInput
        label='City'
        name='profile.address.city'
        type='text'
        required
      />
      <StateSelect name='profile.address.state' required />
      <CreateProfileInput
        label='Zip Code'
        name='profile.address.zip_code'
        type='text'
        format='#####'
        required
      />
      <CreateProfileInput
        label='Phone Number'
        name='profile.phone_number'
        type='text'
        format='(###) ###-####'
        required
      />
      {user.email ? (
        <>
          <label>Email:</label>
          <br />
          <input
            type='email'
            id='email'
            name='profile.email'
            value={user?.email}
            readOnly
          />
        </>
      ) : (
        <CreateProfileInput label='Email' name='email' type='text' required />
      )}
    </div>
  );
};

export default PersonalInformationForm;
